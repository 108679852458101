import React, { Component } from "react";
import IncidentListTable from "./IncidentListTable";
import { apiURLList, apiCall } from "../../_axios";
import CustomAlerts from "../../../components/CustomAlerts";
import { StarProgress } from "@equinor/eds-core-react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { routingPath } from "../../../Constant";

class MyIncidentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableContent: [],
      loading: true,
      dialogOpen: false,
      snackBarOpen: false,
      totalRecords: 0,
    };
  }

  componentDidMount = async () => {
    //calling api to fetch all incidents from database when page loads for the first time.
    this.fetchIncidents();
  };

  componentDidUpdate = (prevProps, prevState) => {
    //for delete functionality from incident listing table page
    //when delete button is clicked
    if (this.props.location.state !== undefined) {
      if (this.state.deleteID !== null)
        apiCall(
          this.props.getAccessToken,
          this.props.userAccount,
          this.props.accessToken,
          apiURLList.mySiops,
          "get"
        )
          .then((apiResponse) => {
            if (apiResponse.status === 200 || apiResponse.status === 204) {
              this.setState({
                snackBarOpen: true,
                tableContent:
                  apiResponse.status === 204 ? [] : apiResponse.data,
                totalRecords: apiResponse.data.length,
                snackBarStatus: prevProps.location.state.status,
                snackBarMessage: prevProps.location.state.message,
                deleteID: null,
                dialogOpen: false,
              });
            } else {
              this.setState({
                snackBarOpen: true,
                tableContent: prevState.tableContent,
                snackBarStatus: "error",
                snackBarMessage: apiResponse.message,
                deleteID: null,
                dialogOpen: false,
              });
            }
            //the below line is needed to reset the location props so that subsequent deletes are handled correctly
            this.props.history.push({
              pathname: routingPath.MY_SIOP,
            });
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  //function to call api to fetch all incidents and set the state of component with those incidents.

  fetchIncidents = async () => {
    let apiResponse = {};

    if (this.props.accessToken) {
      apiResponse = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.mySiops,
        "get"
      );

      if (apiResponse.status === 200 || apiResponse.status === 204) {
        //for 204 status, set the table content to empty, for 200, set to data sent from db
        let content;
        if (apiResponse.status === 200) {
          content = apiResponse.data;
        } else {
          content = [];
        }

        this.setState({
          tableContent: content,
          loading: false,
          totalRecords: apiResponse.data.length,
        });
      } else {
        this.setState({
          snackBarOpen: true,
          snackBarMessage: apiResponse.message,
          snackBarStatus: "error",
        });
      }
    } else {
      console.log("accessToken not available, hence skipping axios get call");
    }
  };

  deleteSIOP = async () => {
    var response = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiURLList.siop,
      "delete",
      { params: { ID: this.state.deleteID } }
    );

    var status = "error";
    var message = response.data;
    if (response.status === 200) {
      status = "success";
      message = response.data;
    }
    this.props.history.push({
      pathname: routingPath.MY_SIOP,
      state: { status: status, message: message },
    });
  };

  actionClicked = (id, operation) => {
    var path = routingPath.CREATE + "/" + id;
    var viewStatus = true;
    var autoSaveFlag = false;

    if (operation === "onePager") {
      this.props.history.push(`/onePager?incidentID=${id}&draft=true`);
    } else {
      if (operation === "edit") {
        viewStatus = false;
        autoSaveFlag = true;
      }

      this.props.history.push({
        pathname: path,
        state: {
          id: id,
          viewStatus: viewStatus,
          approverCommentStatus: true,
          autoSaveFlag: autoSaveFlag,
        },
      });
    }
  };

  deleteClicked = (id) => {
    this.setState({ dialogOpen: true, deleteID: id });
  };

  dialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  snackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  render() {
    var alertJSX = (
      <CustomAlerts
        dialogConfirmation={this.deleteSIOP}
        handleDialogClose={this.dialogClose}
        dialogOpen={this.state.dialogOpen}
        dialogText="Are you sure you want to delete the SIOP?"
        snackBarOpen={this.state.snackBarOpen}
        snackBarText={this.state.snackBarMessage}
        snackBarStatus={this.state.snackBarStatus}
        handleSnackBarClose={this.snackBarClose}
      />
    );

    var incidentListJSX = (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Box m={5}>
              <Typography>
                Total SIOP count : {this.state.totalRecords}
              </Typography>
            </Box>
            <IncidentListTable
              tableContent={this.state.tableContent}
              path={this.props.location.pathname}
              deleteClicked={this.deleteClicked}
              actionClicked={this.actionClicked}
              totalRecord={this.state.totalRecords}
            />
            {alertJSX}
          </Grid>
        </Grid>
      </React.Fragment>
    );

    //Load data when available, else show spinner
    if (this.state.loading) {
      incidentListJSX = (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
          {alertJSX}
        </React.Fragment>
      );
    }
    return incidentListJSX;
  }
}

export default MyIncidentList;
