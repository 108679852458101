// @ts-ignore
import React, { Component } from "react";
import "./App.css";
import Create from "./Create/Create";
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import NavbarHeader from "../components/Navbar/Navbar";
import MyIncidentList from "./DataListing/IncidentList/MyIncidentList";
import ApproveList from "./DataListing/IncidentList/ApproveList";
import Home from "./Home/Home";
import withAuth from "../containers/authentication/withAuth";
import OnePager from "../containers/OnePager/OnePager";
import {routingPath} from "../Constant";
import UserManual from "../containers/UserManual/UserManual";
import Chart from "./Chart/Chart";
import DataListing from "./DataListing/DataListing";
import Usenavbar from "./Usenavbar";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <Usenavbar> <NavbarHeader
            roles={this.props.roles}
            getAccessToken={this.props.getAccessToken}
            accessToken={this.props.accessToken}
            userAccount={this.props.userAccount}
          /></Usenavbar>
        <div>
          <Route path={routingPath.HOME} exact render={(props) => <Home {...this.props} />} />
          <Route path={"/chart"} exact render={(props) => <Chart {...this.props} />} />
          <Route
            path={routingPath.ALL_SIOP}
            exact
            render={(props) => <DataListing {...this.props} {...props} />}
          />
          {/* <Route
            path={routingPath.ALL_SIOP_EVENTS}
            exact
            render={(props) => <EventList {...this.props} {...props} />}
          /> */}
          <Route
            path={routingPath.MY_SIOP}
            exact
            render={(props) => <MyIncidentList {...this.props} {...props} />}
          />
          <Route
            path={routingPath.CREATE}
            exact
            render={(props) => <Create {...this.props} {...props} />}
          />
          <Route
            path={routingPath.EDIT_INCIDENT}
            exact
            render={(props) => <Create {...this.props} {...props} />}
          />
          <Route
            path={routingPath.APPROVAL_LIST}
            exact
            render={(props) => <ApproveList {...this.props} {...props} />}
          />
          <Route
            path={routingPath.ONE_PAGER}
            exact
            render={(props) => <OnePager {...this.props} {...props} />}
          />
          <Route
            path={routingPath.USER_MANUAL}
            exact
            render={(props) => <UserManual {...this.props} {...props} />}
          />          
        </div>
      </BrowserRouter>
    );
  }
}

export default withAuth(App);
