export const config = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority : 'https://login.microsoftonline.com/3aa4a235-b6e2-48d5-9195-7fcf05b459b0',
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        //redirectUri: "https://siop-react-app-dev.azurewebsites.net"
    }
};

export const loginRequest = {
    scopes: ["User.Read","openid","profile"]
};

export const tokenRequest = {
    scopes: [process.env.REACT_APP_TOKEN_SCOPE]
};