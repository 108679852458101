import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import Paper from "@material-ui/core/Paper";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";

//NOTE: if multiple videos are to be added in future, then make sure to that a specific parameter to distinguish image/video would
//have is passed to this component (eg; fileType: "image/video/xml/pdf") . The functionality previewMedia() would fail if
//multiple videos or any other ype of file needs to be passed in future.

function Media(props) {
  const textLabel =
    props.id === "video"
      ? props.source === null || props.source === ""
        ? "Upload Video"
        : "Uploaded video"
      : props.source === null || props.source === ""
      ? "Upload Image"
      : "Uploaded image";

  const previewMedia = () => {
    if (props.id === "video") {
      return (
        <div>
          <video
            src={props.source}
            style={{ height: 320, width: 480, borderStyle: "groove" }}
            controls={true}
          />
        </div>
      );
    } else {
      return (
        <div>
          <img
            src={props.source}
            alt="Upload"
            style={{ height: 320, width: 480, borderStyle: "groove" }}
          />
        </div>
      );
    }
  };

  const buttonOptions = () => {
    if (!props.activeView) {
      return (
        <React.Fragment>
          <div className="form-group col-2">
            <Button component="label">
              <BackupOutlinedIcon />
              <input
                id={props.displayImageId}
                accept={props.mediaType}
                name={props.id}
                type="file"
                onChange={props.mediaChange}
                hidden
              />{" "}
              Upload
            </Button>
          </div>
          <div className="form-group col-2">
            <Button
              onClick={(event) =>
                props.onDelete(event, props.id, props.displayImageId)
              }
            >
              <HighlightOffOutlinedIcon /> Delete
            </Button>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <InputLabel>{textLabel}</InputLabel>
      <Paper
        elevation={3}
        style={{ height: 320, width: 480, marginBottom: "10px" }}
      >
        {previewMedia()}
      </Paper>
      <div className="form-row">{buttonOptions()}</div>
    </React.Fragment>
  );
}

export default Media;
