import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Chip } from "@equinor/eds-core-react";

const style = (value) => {
  const textColor = "black";
  if (value === 1 || value === 2) {
    return { backgroundColor: "#F54135", color: textColor };
  }
  if (value === 3) {
    return { backgroundColor: "yellow", color: textColor };
  }
  if (value === 5 || value === 4 || value === 0) {
    return { backgroundColor: "#32CD32", color: textColor };
  }
  if (value === null) {
    return { backgroundColor: "white", color: textColor };
  }
};

const Consequence = (props) => {
  let consequenceEntry = props.consequences.map((value, index) => {
    //check one pager flag status only if it is onepager page.
    // Since same consequence component is used for displaying consequence category everywhere, pathname is necessary to check
    let conseqDescription;
    if (!props.pathname.includes("onePager")) {
      value.onePagerFlag = true;
      conseqDescription = value.categoryDescription;
    } else {
      conseqDescription =
        value.consequenceCategory?.description === "Others"
          ? value?.category
          : value.consequenceCategory?.description;
    }

    if (value.onePagerFlag) {
      return (
        <Grid container key={index}>
          <Grid item xs={6}>
            <Typography>
              <strong>{conseqDescription}</strong>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Chip style={style(value.actual)}>{value.actual}</Chip>
          </Grid>
          <Grid item xs={3}>
            <Chip variant="active" style={style(value.potential)}>
              {value.potential}
            </Chip>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={10}>
              <Typography>{value.description1}</Typography>
              <Typography>{value.description2}</Typography>
              <Typography>{value.description3}</Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  });

  let consequenceView = (
    <div style={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6} xl={6}>
          <Typography variant="h6">Consequence</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3} xl={3}>
          <Typography variant="h6">Actual</Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3} xl={3}>
          <Typography variant="h6">Potential</Typography>
        </Grid>
      </Grid>
      <Divider />
      {consequenceEntry}
    </div>
  );

  if (props.consequences.length === 0)
    consequenceView = (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              No consequences for this incident have been captured. Please
              update the same, so that the one pager gets updated.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );

  return <React.Fragment>{consequenceView}</React.Fragment>;
};

export default Consequence;
