import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007079",
    },
    secondary: {
      main: "#a8ced1",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ["Equinor"].join(","),
    button: {
      textTransform: "none",
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      margin: "dense",
      fullWidth: true,
      InputLabelProps: {
        shrink: true,
      },
      SelectProps: {
        autoWidth: false,
      },
    },
    MuiButton: {
      variant: "contained",
      color: "primary",
      size: "small",
      disableTouchRipple: true,
    },
    MuiIcon: {
      fontSize: "small",
    },
    MuiTooltip: {
      enterDelay: 500,
      placement: "top",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        marginRight: "10px",
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#007079',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: "2px",
      },
    },
    MuiTypography: {
      root: {
        marginTop: "5px",
      },
    },
    MuiPopover: {
      paper: {
        minWidth: "15%",
        maxWidth: "50%",
        minHeight: "15%",
        maxHeight: "50%",
        paddingRight: "10px",
        paddingLeft: "10px",
      },
    },
    MuiFormHelperText: {
      root: {
        color: "green",
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 450,
      },
    },
    MuiFormLabel: {
      root: {
        color: "black",
        fontWeight: 450,
      },
    },
  },
});

export default theme;
