import React, { Component } from "react";
import { config, loginRequest, tokenRequest } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { StarProgress } from "@equinor/eds-core-react";
import {jwtDecode as jwt_decode} from 'jwt-decode';
//HOC Component which returns authenticationn parameters in the props of the component passed to it
//The HOC component returns the authentication status, user name and a function handler to get access tokens

export default function withAuth(WrappedComponent) {
  let accessTokenData;
  

  class AuthenticatedComponent extends Component {
    constructor(props) {
      super(props);
      this.state = { authStatus: false, userName: null };

      this.msalObject = new PublicClientApplication(config);
    }

    componentDidMount() {
      //Check authentication status and if the user is not authenticated
      // check the redirect response to check for id token details

      if (!this.state.authStatus) {
        this.signInHandler();
      }
    }

    signInHandler = () => {
      this.msalObject
        .handleRedirectPromise()
        .then(() => {
          this.handleResponse();
        })
        .catch((err) => {
          this.setState({ error: err });
        });
    };
    //Function which handles the redirect functionality
    handleResponse = (response) => {
      //Retrieve the account details of the user
      //console.log("inside handle response");
      const userAccounts = this.msalObject.getAllAccounts();
    
      //If the user details are not present then redirect to Azure AD login page with scope parameter from the authConfig.js
      if (userAccounts.length === 0) {
        this.msalObject.loginRedirect(loginRequest);

        //If the user has multiple accounts or single account choose the first account and then set the state details
      } else if (userAccounts.length > 0) {
        //console.log("inside got 1 user detail");

        // let roles = [];

        // if (userAccounts[0].idTokenClaims.roles) {
        //   roles = userAccounts[0].idTokenClaims.roles;
        // }

        this.msalObject
          .acquireTokenSilent({
            account: userAccounts[0],
            scopes: tokenRequest.scopes,
          })

          .then((token) => {
           
            accessTokenData = jwt_decode(token.accessToken);
       
            this.setState({
              userName: userAccounts[0].name,
              authStatus: true,
              userAccount: userAccounts[0],
              roles: accessTokenData.roles,
              accessToken: token.accessToken,
            });
          })
          .catch((err) => {
            console.log("Error in getting token : ", err);
            this.setState({
              userName: userAccounts[0].name,
              authStatus: true,
              userAccount: userAccounts[0],
              roles: accessTokenData.roles,
            });
          });

        //console.log("update state completed");
      }
    };

    //This function retrieves an access token from Azure AD
    getAccessToken = async (userAccount) => {
      //console.log("going for access token");
      const token = await this.msalObject.acquireTokenSilent({
        account: userAccount,
        scopes: tokenRequest.scopes,
      });

      // .then((token) => {
      //   //console.log("token: ", token.accessToken);
      //   this.setState({ accessToken: token.accessToken });
      //   return token.accessToken;
      // });
      this.setState({ accessToken: token.accessToken });
      return token.accessToken;
    };

    render() {
      let authenticationComponent = (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
        </React.Fragment>
      );

      if (this.state.authStatus)
        authenticationComponent = (
          <WrappedComponent
            isAuthenticated={this.state.authStatus}
            user={this.state.userName}
            getAccessToken={this.getAccessToken}
            signIn={this.signInHandler}
            accessToken={this.state.accessToken}
            userAccount={this.state.userAccount}
            roles={accessTokenData.roles}
            {...this.props}
          />
        );

      return authenticationComponent;
    }
  }

  return AuthenticatedComponent;
}
