import React from "react";
import { Button, Accordion } from "@equinor/eds-core-react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import { Typography } from "@material-ui/core";
import DateSlider from "./DateSlider";
import Tooltip from "@material-ui/core/Tooltip";

export default function IncidentSearchBar(props) {
  //redirect chip delete functionality to parent compoenent
  const handleDelete = (value, name) => () => {
    props.handleDelete(value, name);
  };

  const country = (
    <TextField
      name="country"
      label="Country"
      value={props.searchParams.countryText}
      onChange={(event) => props.textChange(event, "countryText")}
      onKeyPress={(event) => props.searchValueOnEnter(event, "countryText")}
    ></TextField>
  );

  const countryChip = props.searchParams.country.map((country) => {
    return (
      <Chip
        key={country}
        label={country}
        onDelete={handleDelete(country, "country")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  const unit = (
    <TextField
      name="unit"
      label="Unit"
      value={props.searchParams.unitText}
      onChange={(event) => props.textChange(event, "unitText")}
      onKeyPress={(event) => props.searchValueOnEnter(event, "unitText")}
    ></TextField>
  );

  const unitChip = props.searchParams.unit.map((unit) => {
    return (
      <Chip
        key={unit}
        label={unit}
        onDelete={handleDelete(unit, "unit")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  const businessArea = (
    <TextField
      name="businessArea"
      label="Business Area"
      value={props.searchParams.businessAreaText}
      onChange={(event) => props.textChange(event, "businessAreaText")}
      onKeyPress={(event) =>
        props.searchValueOnEnter(event, "businessAreaText")
      }
    ></TextField>
  );

  const businessAreaChip = props.searchParams.businessArea.map((baObj) => {
    return (
      <Chip
        key={baObj}
        label={baObj}
        onDelete={handleDelete(baObj, "businessArea")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  const category = (
    <TextField
      name="category"
      label="Consequence"
      value={props.searchParams.categoryText}
      onChange={(event) => props.textChange(event, "categoryText")}
      onKeyPress={(event) => props.searchValueOnEnter(event, "categoryText")}
    ></TextField>
  );

  const categoryChip = props.searchParams.category.map((categoryObj) => {
    return (
      <Chip
        key={categoryObj}
        label={categoryObj}
        onDelete={handleDelete(categoryObj, "category")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  //filling area list from reference data
  let areaList;
  let lifeSavingRuleList;
  let disciplineList;

  if (props.referenceData.areaLst !== undefined) {
    areaList = props.referenceData.areaLst.map((areaListObj) => {
      return (
        <MenuItem key={areaListObj.id} value={areaListObj.id}>
          {areaListObj.name}
        </MenuItem>
      );
    });
  } else {
    console.warn("Event.js; area list is empty. Check data received from api");
    areaList = null;
  }

  if (props.referenceData.lifeSavingRuleLst !== undefined) {
    lifeSavingRuleList = props.referenceData.lifeSavingRuleLst.map((lsrObj) => {
      return (
        <MenuItem key={lsrObj.id} value={lsrObj.id}>
          {lsrObj.name}
        </MenuItem>
      );
    });
  } else {
    console.warn(
      "Event.js; Life saving rule list is empty. Check data received from api"
    );
    lifeSavingRuleList = null;
  }

  if (props.referenceData.disciplineLst !== undefined) {
    disciplineList = props.referenceData.disciplineLst.map((disciplineObj) => {
      return (
        <MenuItem key={disciplineObj.id} value={disciplineObj.id}>
          {disciplineObj.name}
        </MenuItem>
      );
    });
  } else {
    console.warn(
      "Event.js; Discipline list is empty. Check data received from api"
    );
    disciplineList = null;
  }

  const areaChipData = props.searchParams.area.map((areaObj) => {
    let areaSelection = null;
    props.referenceData.areaLst.forEach((element) => {
      if (element.id === areaObj) {
        areaSelection = element.name;
      }
    });
    return (
      <Chip
        key={areaObj}
        label={areaSelection}
        onDelete={handleDelete(areaObj, "area")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  const lsrChipData = props.searchParams.lifeSavingRule.map((lsrObj) => {
    let lsrSelection = null;
    props.referenceData.lifeSavingRuleLst.forEach((element) => {
      if (element.id === lsrObj) {
        lsrSelection = element.name;
      }
    });
    return (
      <Chip
        key={lsrObj}
        label={lsrSelection}
        onDelete={handleDelete(lsrObj, "lifeSavingRule")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  const disciplineChipData = props.searchParams.discipline.map((discObj) => {
    let disciplineSelection = null;
    props.referenceData.disciplineLst.forEach((element) => {
      if (element.id === discObj) {
        disciplineSelection = element.name;
      }
    });
    return (
      <Chip
        key={discObj}
        label={disciplineSelection}
        onDelete={handleDelete(discObj, "discipline")}
        variant="outlined"
        //className={classes.chip}
      />
    );
  });

  return (
    <div>
      <React.Fragment>
        <Accordion chevronPosition="left">
          <Accordion.Item>
            <Accordion.Header>
              <Typography>
                <SearchOutlinedIcon />
                Search
              </Typography>
            </Accordion.Header>
            <Accordion.Panel>
              <Grid container spacing={4}>
                <Grid item xs>
                  {country}
                  {countryChip}
                </Grid>

                <Grid item xs>
                  {unit}
                  {unitChip}
                </Grid>

                <Grid item xs>
                  {businessArea}
                  {businessAreaChip}
                </Grid>

                <Grid item xs>
                  {category}
                  {categoryChip}
                </Grid>

                <Grid item xs>
                  <TextField
                    name="area"
                    label="Area"
                    select
                    value={""}
                    //value={null}
                    onChange={props.valueChange}
                  >
                    <MenuItem disabled></MenuItem>
                    {areaList}
                  </TextField>
                  {areaChipData}
                </Grid>

                <Grid item xs>
                  <TextField
                    name="lifeSavingRule"
                    label="Life saving rule"
                    select
                    //value={props.searchParams.area}
                    value={""}
                    onChange={props.valueChange}
                  >
                    <MenuItem disabled></MenuItem>
                    {lifeSavingRuleList}
                  </TextField>
                  {lsrChipData}
                </Grid>

                <Grid item xs>
                  <TextField
                    name="discipline"
                    label="Discipline"
                    select
                    //value={props.searchParams.area}
                    value={""}
                    onChange={props.valueChange}
                  >
                    <MenuItem disabled></MenuItem>
                    {disciplineList}
                  </TextField>
                  {disciplineChipData}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <TextField
                    name="customSearch"
                    label="General Search"
                    //value={props.searchParams.area}
                    value={props.searchParams.customSearch}
                    onChange={props.valueChange}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <DateSlider
                    yearRangeChange={props.yearRangeChange}
                    initialValue={props.searchParams.yearRange}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <Tooltip title="Reset selection">
                    <Button variant="ghost_icon" onClick={props.reset}>
                      <RotateLeftOutlinedIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Search">
                    <Button variant="ghost_icon" onClick={props.search}>
                      <SearchOutlinedIcon />
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontStyle: "italic"
                    }}
                    gutterBottom
                  >
                    *Click on search icon <SearchOutlinedIcon /> to search the selection.
                  </Typography>
                </Grid>
              </Grid>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </React.Fragment>
    </div>
  );
}
