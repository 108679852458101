import React, { Component } from "react";
import IncidentListTable from "./IncidentListTable";
import { apiURLList, apiCall } from "../../_axios";
import CustomAlerts from "../../../components/CustomAlerts";
import { StarProgress } from "@equinor/eds-core-react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { routingPath } from "../../../Constant";

class ApproveList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableContent: [],
      loading: true,
      dialogOpen: false,
      snackBarOpen: false,
      approverDialogOpen: false,
      totalRecords: 0,
      incidentId: -1,
      approverComment: "",
    };
  }

  componentDidMount = async () => {
    //calling api to fetch all incidents from database when page loads for the first time.
    this.fetchIncidents();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.location.state !== undefined) {
      if (this.state.deleteID !== null)
        apiCall(
          this.props.getAccessToken,
          this.props.userAccount,
          this.props.accessToken,
          apiURLList.approvalSiops,
          "get"
        )
          .then((apiResponse) => {
            if (apiResponse.status === 200 || apiResponse.status === 204) {
              this.setState({
                snackBarOpen: true,
                tableContent:
                  apiResponse.status === 204 ? [] : apiResponse.data,
                totalRecords: apiResponse.data.length,
                snackBarStatus: prevProps.location.state.status,
                snackBarMessage: prevProps.location.state.message,
                deleteID: null,
                dialogOpen: false,
              });
            } else {
              this.setState({
                snackBarOpen: true,
                tableContent: prevState.tableContent,
                snackBarStatus: "error",
                snackBarMessage: apiResponse.message,
                deleteID: null,
                dialogOpen: false,
              });
            }
            //the below line is needed to reset the location props so that subsequent deletes are handled correctly
            this.props.history.push({
              pathname: routingPath.MY_SIOP,
            });
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  //function to call api to fetch all incidents pending approval and set the state of component with those incidents.

  fetchIncidents = async () => {
    let apiResponse = {};

    if (this.props.accessToken) {
      apiResponse = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.approvalSiops,
        "get"
      );

      if (apiResponse.status === 200 || apiResponse.status === 204) {
        //for 204 status, set the table content to empty, for 200, set to data sent from db
        let content;
        if (apiResponse.status === 200) {
          content = apiResponse.data;
        } else {
          content = [];
        }

        this.setState({
          tableContent: content,
          loading: false,
          totalRecords: apiResponse.data.length,
        });
      } else {
        this.setState({
          snackBarOpen: true,
          snackBarMessage: apiResponse.message,
          snackBarStatus: "error",
        });
      }
    } else {
      console.log("accessToken not available, hence skipping axios get call");
    }
  };

  actionClicked = (id, operation) => {
    var path = routingPath.CREATE + "/" + id;
    var viewStatus = true;

    if (operation === "onePager") {
      this.props.history.push(`/onePager?incidentID=${id}&draft=true`);
    } else {
      if (operation === "edit") {
        viewStatus = false;
      }

      this.props.history.push({
        pathname: path,
        state: {
          id: id,
          viewStatus: viewStatus,
          approverCommentStatus: true,
        },
      });
    }
  };

  //approve/reject handler
  onActionHandler = (id) => {
    this.setState({ approverDialogOpen: true, incidentId: id });
  };

  onApproverComment = (event) => {
    this.setState({ approverComment: event.target.value });
  };

  onApproveRejectHandler = async (actionToBeTaken) => {
    let parameters = {
      IncidentId: this.state.incidentId,
      Status: actionToBeTaken,
      Comment: this.state.approverComment,
    };

    this.setState({ loading: true, approverDialogOpen: false });

    let apiResponse = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiURLList.approverAction,
      "post",
      parameters
    );

    if (apiResponse.status === 200) {
      console.log("success");
      this.setState({
        approverComment: "",
        approverDialogOpen: false,
        snackBarOpen: true,
        snackBarMessage: apiResponse.data,
        snackBarStatus: "success",
      });
      this.fetchIncidents();
    } else {
      this.setState({
        approverComment: "",
        approverDialogOpen: false,
        snackBarOpen: true,
        snackBarMessage: apiResponse.message,
        snackBarStatus: "error",
        loading: false,
      });
    }
  };

  onHandleClose = () => {
    this.setState({ approverDialogOpen: false });
  };

  deleteClicked = (id) => {
    this.setState({ dialogOpen: true, deleteID: id });
  };

  dialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  snackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  render() {
    var approverActionJSX = (
      <Dialog
        open={this.state.approverDialogOpen}
        onClose={this.onHandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Approve/Reject</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in your comments before approving or rejecting
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="approverComment"
            label="Comment"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            value={this.state.approverComment}
            onChange={this.onApproverComment}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.onApproveRejectHandler("true")}
            color="primary"
          >
            Approve
          </Button>
          <Button
            onClick={() => this.onApproveRejectHandler("false")}
            style={{ backgroundColor: "#e52d2d" }}
          >
            Reject
          </Button>
          <Button onClick={this.onHandleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );

    var alertJSX = (
      <CustomAlerts
        handleDialogClose={this.dialogClose}
        dialogOpen={this.state.dialogOpen}
        snackBarOpen={this.state.snackBarOpen}
        snackBarText={this.state.snackBarMessage}
        snackBarStatus={this.state.snackBarStatus}
        handleSnackBarClose={this.snackBarClose}
      />
    );

    var approveListJSX = (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Box m={5}>
              <Typography>
                Total SIOP count : {this.state.totalRecords}
              </Typography>
            </Box>
            <IncidentListTable
              tableContent={this.state.tableContent}
              path={this.props.location.pathname}
              deleteClicked={this.deleteClicked}
              actionClicked={this.actionClicked}
              actionHandler={this.onActionHandler}
              totalRecord={this.state.totalRecords}
            />
            {alertJSX}
            {approverActionJSX}
          </Grid>
        </Grid>
      </React.Fragment>
    );

    //Load data when available, else show spinner
    if (this.state.loading) {
      approveListJSX = (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
          {alertJSX}
        </React.Fragment>
      );
    }
    return approveListJSX;
  }
}

export default ApproveList;
