import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "@equinor/eds-core-react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Constant, { routingPath } from "../../../Constant";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonMaterial from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";

//userId and synergiId is being passed as parameter with Edit and view button.
//This would let the component(which will be rendered) to load the data related to user or synergiId

const IncidentListTable = (props) => {
  var defaults = false;

  const tableBody = props.tableContent.map((element) => {
    const button = () => {
      if (props.path === routingPath.MY_SIOP) {
        return (
          <React.Fragment>
            <Tooltip title="View" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.id, "view");
                }}
              >
                <VisibilityOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Edit" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.id, "edit");
                }}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Delete" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.deleteClicked(element.id);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </Button>
            </Tooltip>
          </React.Fragment>
        );
      } else if (props.path === routingPath.APPROVAL_LIST) {
        return (
          <React.Fragment>
            <Tooltip title="Edit" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.id, "edit");
                }}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Approve/Reject" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionHandler(element.id);
                }}
              >
                <CheckCircleOutlineOutlinedIcon />
              </Button>
            </Tooltip>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };

    //limit number of character s in description
    const abbreviatedDescription = (description) => {
      if (description.length <= Constant.TEXT_LENGTH) {
        return description;
      } else {
        return description.substring(0, Constant.TEXT_LENGTH) + "...";
      }
    };

    //change format to dd-mmm-yyyy
    let dateFormatChange = (date) => {
      let dateObj = new Date(date?.split("/")[2], date?.split("/")[1]-1, date?.split("/")[0]); //to getnew Date(year, month, date)
      const month = dateObj.toLocaleString("default", { month: "short" });
      return `${dateObj.getDate()}-${month}-${dateObj.getFullYear()}`;
    };

    if (props.path === routingPath.ALL_SIOP) defaults = true;

    if (props.path === routingPath.HOME) defaults = true;

    if (props.path === undefined) defaults = true;
    const linkToCaseInSynergi = () => {
      window.open(process.env.REACT_APP_SYNERGIURL + `${element.synergiId}`);
    };
    return (
      <TableRow key={element.id} hover={true}>
        <TableCell style={{ width: 50 }}><Link href="" style={{textDecoration: 'none',color:"#007079"}} onClick={linkToCaseInSynergi}>{element.synergiId}</Link></TableCell>
        <TableCell style={{ width: 150 }}>{dateFormatChange(element.incidentDate)}</TableCell>
        <TableCell style={{ width: 280 }}>{element.incidentTitle}</TableCell>
        <TableCell>
          {abbreviatedDescription(element.incidentDescription)}
        </TableCell>
        <TableCell style={defaults ? { width: 350 } : { width: 200 }}>
          {/* view, edit and delete buttons */}
          {button()}

          {/* one pager button; use can choose one pager in 1 of 3 language if on all siop page*/}
          {defaults ? (
            <ButtonGroup variant="text" color="primary">
              <ButtonMaterial
                onClick={() => {
                  props.actionClicked(
                    element.id,
                    "onePager",
                    Constant.LANGUAGE_ENGLISH
                  );
                }}
               style={{padding:"12px"}}
               size="small"
              >
                One Pager
              </ButtonMaterial>
              <ButtonMaterial
                onClick={() => {
                  props.actionClicked(
                    element.id,
                    "onePager",
                    Constant.LANGUAGE_NORWEGIAN
                  );
                }}
                style={{paddingRight:"12px"}}
                size="small"
              >
                Ensider
              </ButtonMaterial>
              <ButtonMaterial
                onClick={() => {
                  props.actionClicked(
                    element.id,
                    "onePager",
                    Constant.LANGUAGE_PORTUGUESE
                  );
                }}
                size="small"
              >
                Um Pager
              </ButtonMaterial>
            </ButtonGroup>
          ) : (
            <Tooltip title="One Pager" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.id, "onePager");
                }}
              >
                <AssignmentIcon />
              </Button>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Box m={5}>
      <Grid item>
        <Typography
          style={{
            fontStyle: "italic",
            fontSize: "0.9rem",
          }}
          gutterBottom
        >
          *The below list is sorted based on newest first
        </Typography>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>SynergiID</strong>
            </TableCell>
            <TableCell>
              <strong>Date</strong>
            </TableCell>
            <TableCell>
              <strong>Incident</strong>
            </TableCell>
            <TableCell>
              <strong>Incident Description</strong>
            </TableCell>
            <TableCell style={{paddingLeft:"30px"}}>

              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </Box>
  );
};

export default IncidentListTable;
