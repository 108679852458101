import React, { Component } from "react";
import BarChart from "./BarChart";
import Grid from "@material-ui/core/Grid";
import { StarProgress } from "@equinor/eds-core-react";

class Chart extends Component {
  constructor(props) {
    super(props);
  }

  chartTitle = () => {
    let _chartTitle = "Work Process distribution";

    if (this.props.eventChartType === "Work") {
      _chartTitle = "Work Process distribution";
    }
    if (this.props.eventChartType === "Timp") {
      _chartTitle = "Barrier distribution";
    }

    return _chartTitle;
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            {this.props.chartLoading ? (
              <StarProgress
                color="primary"
                style={{ marginLeft: "50%", marginTop: "0%" }}
              />
            ) : (
              <BarChart
                chartContent={this.props.chartContent}
                chartContentArray={this.props.chartContentArray}
                eventType={this.chartTitle}
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Chart;
