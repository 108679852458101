import React, { Component } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { Chip } from "@equinor/eds-core-react";
import PopoverContent from "./PopoverContent";
import TextField from "@material-ui/core/TextField";

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: null,
      open: false,
      selection: this.props.currentDropDownValue,
    };
  }

  handleClose = () => {
    this.setState({ anchor: null, open: false });
  };

  handleClick = (event) => {
    this.setState({ anchor: event.currentTarget, open: true });
    this.props.onChange(event);
  };

  handleInputs = (selectedValue) => {
    this.setState({ anchor: null, open: false, selection: selectedValue });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.onEventTypeChanged)
      this.setState((prevState) => {
        if (prevState.selection.length > 0) return { selection: [] };
      });

    //to handle dropdown selection view on event reordering. updating state to set to new value
    if (
      prevProps.currentDropDownValue.join("") !==
      this.props.currentDropDownValue.join("")
    ) {
      //snyk wants to use prev props, but as per requirement, current props need to be showed.
      this.setState({
        selection: this.props.currentDropDownValue,
      });
    }
  };

  //Send value to create component
  onDropDownValueSelection = () => {
    this.props.onDropDownSelection(this.state.selection, this.props.identifier);
  };

  render() {
    let anchorEl = this.state.anchor;
    let selections = this.state.selection.map((item, index) => {
      return <Chip key={index}>{item}</Chip>;
    });

    let buttonText = this.props.changeText;
    if (this.state.selection.length === 0) buttonText = this.props.selectText;

    let dropDownSelection = null;

    if (!this.props.isViewActive)
      dropDownSelection = (
        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            disabled={this.props.isViewActive}
            onClick={this.handleClick}
          >
            {buttonText}
          </Button>
        </React.Fragment>
      );

    return (
      <div>
        <div>{selections}</div>
        {dropDownSelection}
        <div className="form-group">
          <TextField
            error={this.props.error}
            helperText={this.props.helperText}
            name="workProcessBarrierText"
            placeholder="Write work process or barrier if not found among dropdown"
            label="Extra text"
            disabled={this.props.isViewActive}
            value={this.props.extraTextValue}
            onChange={this.props.onChange}
          ></TextField>
        </div>
        <Popover
          open={this.state.open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <PopoverContent
            dropDownSelection={this.handleInputs}
            processCancellation={this.handleClose}
            levelOneList={this.props.levelOneList}
            accessToken={this.props.accessToken}
            identifier={this.props.identifier}
            onDropDownValueSelection={(selectedValue, identifier) =>
              this.onDropDownValueSelection(selectedValue, identifier)
            }
          />
        </Popover>
      </div>
    );
  }
}

export default DropDown;
