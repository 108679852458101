import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

//Common module to handle alerts and dialog boxes across the application
//This component can be inserted into any component and provided the right parameters in order to handle the display of dialog boxes
// and snackbars for confirmation or failure of actions

//The component triggers the dialog confirmation function that is passed as prop

//Props that can be passed : 
// For dialog box :
// dialogOpen
// dialogText
// dialogConfirmation (method to be invoked on confirmation)
// handleDialogClose

//For snackbar alert on top right :
// snackBarOpen
// snackBarStatus
// snackBarText
// handleSnackBarClose

//For message dialog :
//dialogMessageText
//dialogMessageLink
//dialogMessageOpen

const CustomAlerts = (props) => {

      let dialogOpen =false;
      let dialogMessageOpen =false;      
      let snackBarOpen =false;   
      
      if (props.dialogOpen)
        dialogOpen = props.dialogOpen

      if (props.dialogMessageOpen)
        dialogMessageOpen = props.dialogMessageOpen

      if (props.snackBarOpen)
        snackBarOpen = props.snackBarOpen

      return(
      <React.Fragment>
        <Dialog
          open={dialogOpen}
          onClose={props.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={props.dialogConfirmation} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogMessageOpen}
          onClose={props.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Information"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.dialogMessageText}
              <a href={props.dialogMessageLink} target="_self">Download</a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleDialogClose} color="primary">
            OK
            </Button>
            </DialogActions>
        </Dialog>        
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBarOpen}
          autoHideDuration={2000}
          onClose={props.handleSnackBarClose}
        >
          <Alert
            onClose={props.handleSnackBarClose}
            severity={props.snackBarStatus}
          >
            {props.snackBarText}
          </Alert>
        </Snackbar>
      </React.Fragment>);
}
export default CustomAlerts;
