import React from "react";
import { Typography } from "@equinor/eds-core-react";
import Box from "@material-ui/core/Box";

const AreaPopover = (props) => {
  return (
    <React.Fragment>
      <Box m={3}>
        <Typography
          onClick={() => props.handleClick("Offshore utility/Living Quarters")}
          onMouseEnter={() =>
            props.handleHover("enter", "Offshore utility/Living Quarters")
          }
          onMouseLeave={() => props.handleHover("leave")}
        >
         1. Offshore utility/Living Quarters
        </Typography>
        <Typography
          onClick={() => props.handleClick("Drilling area")}
          onMouseEnter={() => props.handleHover("enter", "Drilling area")}
          onMouseLeave={() => props.handleHover("leave")}
        >
         2. Drilling area
        </Typography>
        <Typography
          onClick={() => props.handleClick("Material Handling")}
          onMouseEnter={() => props.handleHover("enter", "Material Handling")}
          onMouseLeave={() => props.handleHover("leave")}
        >
         3. Material Handling
        </Typography>
        <Typography
          onClick={() => props.handleClick("Offshore process")}
          onMouseEnter={() => props.handleHover("enter", "Offshore process")}
          onMouseLeave={() => props.handleHover("leave")}
        >
         4. Offshore process
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default AreaPopover;
