import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

let chartLabel = [];
let count = [];

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  labelData = () => {
    chartLabel = [];
    count = [];
    this.props.chartContent.forEach((element) => {
      //do not consider null strings
      if (element.levelText !== "") {
        chartLabel.push(element.levelText);
        count.push(element.count);
      }
    });
  };

  render() {
    this.labelData();
    let data = {
      labels: chartLabel,
      datasets: [
        {
          label: "Event count",
          data: count,
          backgroundColor: ["rgb(115,177,181)"],
          borderColor: ["rgb(115,177,181)"],
          borderWidth: 1,
          //barThickness: 50,
          barPercentage: 0.5,
          //maxBarThickness: 50,
        },
      ],
    };

    let options = {
      indexAxis: "y",
      // Elements options apply to all of the options unless overridden in a dataset
      // In this case, we are setting the border of each horizontal bar to be 2px wide
      elements: {
        bar: {
          borderWidth: 1,
        },
      },
      layout: {
        padding: {
          left: 50,
        },
      },
      //aspectRatio: 4,
      redraw: true,
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "right",
          display: false,
        },
        title: {
          display: true,
          text: this.props.eventType,
        },
      },
    };

    return (
      <React.Fragment>
        <Bar height={600} width={1400} data={data} options={options} />
      </React.Fragment>
    );
  }
}

export default BarChart;
