import React from "react";
import siopImage from "../../assets/oilrig_withyard_withhelicopter.jpg";
import Mapper from 'react-img-mapper';

const ImageMapping = (props) => {

  let mapping = {
    name : "siop-map",
    areas : [
              {_id : "select", shape : "rect", coords : [536,271, 591,291]},
              {_id : "Supply/Service ship", shape : "poly", coords : [453,289,	475,291,	477,285,	498,305,	498,315,	452,304],},
              {_id : "Production well", shape : "poly", coords : [563,470, 563,572, 579,601, 595,613, 595,624, 611,626, 651,633, 716,616, 692,603, 605,605, 582,582, 576,542, 575,470]},
              {_id : "Subsea", shape : "rect", coords : [657,380, 743,443]},
              {_id : "Offsite", shape : "poly", coords : [83,287, 182,270, 230,291, 230,311, 180,326, 81,312]},
              {_id : "Onshore Drilling", shape: "poly", coords : [198,265, 207,261, 207,219, 217,219, 215,267]},
              {_id : "Drilling well", shape: "poly", coords : [538,578, 422,566, 380,514, 365,449, 365,305, 378,305, 378,444, 401,522, 451,554, 535,568]},
              {_id : "Drilling rig", shape: "poly", coords : [347,273, 369,271, 368,260, 375,259, 375,271, 384,270, 395,281, 391,296, 353,302, 346,285]},
              {_id : "Wind farm", shape: "poly", coords : [395,236, 512,224, 511,262, 395,262]},
              {_id : "Safety zone", shape: "rect", coords : [517,312, 620,334]},
              {_id : "Tanker ship", shape: "poly", coords : [688,274, 700,273, 700,284, 787,286, 790,304, 685,305]},              
              {_id : "Offshore substructure or risers", shape: "poly", coords : [508,433, 530,340, 597,340, 618,446, 542,472]},   
              {_id : "Pipeline", shape: "rect", coords : [803,326, 1007,339]},
              {_id : "Harbour", shape: "poly", coords : [993,268, 1014,263, 1079,335, 1057,336]},             
              {_id : "Truck/ car", shape: "rect", coords : [962,225, 1026,243]},                           
              {_id : "Storage tanks", shape: "poly", coords : [1081,263, 1080,291,  1100,309, 1174,309, 1174,274, 1123,271, 1102,257]},                                         
              {_id : "Office", shape: "poly", coords : [1126,233, 1138,233, 1165,246, 1166,268, 1148,268]},    
              {_id : "Onshore process", shape: "poly", coords : [1171,251, 1210,212, 1221,258, 1244,278, 1248,311, 1237,314, 1174,267]},                                         
              {_id : "Yard/ Workshop", shape: "poly", coords : [295,216, 304,218, 329,205, 349,228, 335,235, 335,256, 319,256, 289,226]},
              {_id : "Helicopter", shape: "poly", coords : [903,114, 900,129, 912,137, 908,148, 921,153, 938,152, 932,124]},
              {_id : "Onshore utility", shape: "poly", coords : [1051,262, 1088,321, 1260,317, 1197,185, 1086,176]},
            ]
          }

return (
    <React.Fragment>
      <Mapper
        src={siopImage}
        map={mapping}
        width={1388}
        height={704}
        onMouseEnter={(area) => {
          props.handleHover("enter", area._id);
        }}
        onMouseLeave={() => {
          props.handleHover("leave");
        }}
        onClick={(event) => {
          props.handleClick(event._id);
        }}
      />
    </React.Fragment>
  );
}

export default ImageMapping;