import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";

function ApproverComment(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCommentClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCommentClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const showComments = props.approverComment.map((comment, index) => {
    if (comment.userComment) {
      return (
        <Paper
          key={index}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: props.loggedInUser === comment.userName ? "25%" : "5px",
            marginRight: props.loggedInUser === comment.userName ? "5px" : "25%",
            backgroundColor:
              props.loggedInUser === comment.userName
                ? "antiquewhite"
                : "wheat", //gainsboro, tan, wheat
            borderRadius: "10px",
            borderRightStyle: props.loggedInUser === comment.userName ? "solid" : "hidden",
            borderLeftStyle: props.loggedInUser === comment.userName ? "hidden" : "solid",
            minWidth: "75%",
          }}
        >
          <Typography
            style={{
              paddingLeft: "10px",
              paddingTop: "10px",
              paddingRight: "10px",
            }}
          >
            <Chip
              label={comment.userName}
              color={
                props.loggedInUser === comment.userName
                  ? "primary"
                  : "secondary"
              }
            />
            <Typography variant="caption" display="block">
              {comment.commentDate}
            </Typography>
            <Typography variant="body1" >
              {comment.userComment}
            </Typography>
          </Typography>
        </Paper>
      );
    } else {
      return null;
    }
  });

  return (
    <div>
      <Badge
        //component="button"
        color="error"
        variant="dot"
        //invisible={props.approverComment !== null ? false : true}
        invisible={true}
        //onClick={handleCommentClick}
      >
        <IconButton color="primary" onClick={handleCommentClick}>
          <MessageOutlinedIcon />
        </IconButton>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCommentClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {showComments}
      </Popover>
    </div>
  );
}

export default ApproverComment;
