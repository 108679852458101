import React, { Component } from "react";
import { withRouter } from "react-router";
import ImageMapping from "./ImageMapping";
import AreaPopover from "./AreaPopover";
import CustomAlerts from "../../components/CustomAlerts";
import { Typography } from "@equinor/eds-core-react";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { apiURLList, apiCall } from "../_axios";
import { StarProgress } from "@equinor/eds-core-react";
import { routingPath } from "../../Constant";
import Grid from "@material-ui/core/Grid";
import IncidentListTable from "../DataListing/IncidentList/IncidentListTable";
import { HashLink } from 'react-router-hash-link';
class Home extends Component {
  constructor(props) {
    super(props);
    let status, open, message;

    if (this.props.location.state === undefined) {
      status = "error";
      open = false;
      message = "";
    } else {
      if (this.props.location.state.status) status = "success";
      open = true;
      message = this.props.location.state.message;
    }

    this.state = {
      snackBarStatus: status,
      snackBarOpen: open,
      snackBarMessage: message,
      popoverShow: false,
      areaHovered: "",
      anchorElement: null,
      siopsByArea: null,
      loadingRecent: true,
    };

    this.divRef = React.createRef();
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  handlePopoverClose = () => {
    this.setState({ popoverShow: false });
  };

  searchInArea = (field, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].area === field) {
        return array[i];
      }
    }

    return null;
  };

  handleHover = (action, element) => {
    if (action === "leave") this.setState({ hoveredValue: null });
    else
      this.setState((prevState) => {
        return {
          hoveredValue: this.searchInArea(element, prevState.siopsByArea),
        };
      });
  };

  handleClick = (element) => {
    if (element === "select") this.handlePopover();
    else {
      this.props.search(this.state.hoveredValue.area_id);
      this.props.history.push({ pathname: routingPath.ALL_SIOP });
    }
  };

  handlePopover = () => {
    this.setState({ popoverShow: true, anchorElement: this.divRef.current });
  };

  componentDidMount = async () => {
    const referenceResponseData = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiURLList.referenceData,
      "get"
    );

    if (referenceResponseData.status === 200) {
      this.props.reference(referenceResponseData.data);
    } else
      this.setState({
        snackBarOpen: true,
        snackBarMessage: referenceResponseData.message,
        snackBarStatus: "error",
      });

    const recentSIOPs = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiURLList.allSiopSearch,
      "get",
      {
        params: {
          pageNumber: 1,
          recordsPerPage: 5,
        },
      }
    );

    if (recentSIOPs.status === 200) {
      this.setState({
        recentSIOPs: recentSIOPs.data.incidents,
        loadingRecent: false,
      });
    } else
      this.setState({
        snackBarOpen: true,
        snackBarMessage: recentSIOPs.message,
        snackBarStatus: "error",
      });

    const siopsByAreaResponse = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiURLList.siopsByArea,
      "get"
    );

    if (siopsByAreaResponse.status === 200) {
      this.setState({
        siopsByArea: siopsByAreaResponse.data,
      });
    } else
      this.setState({
        snackBarOpen: true,
        snackBarMessage: siopsByAreaResponse.message,
        snackBarStatus: "error",
      });
  };

  render() {
    let textMessage =(<div>Move your mouse pointer over the image to search incidents based on different areas or scroll down to get the list of<HashLink smooth to='/#recentsiop' style={{textDecoration: 'none',color:"#007079"}}> Recent SIOP </HashLink></div>
           )
    if (this.state.hoveredValue)
      textMessage =
        "Click here to search based on " +
        this.state.hoveredValue.area +
        " which has " +
        this.state.hoveredValue.count +
        " SIOP(s)";

    if (this.state.hoveredValue === "select")
      textMessage = "Click here to get additional areas";

    let image = (
      <StarProgress
        color="primary"
        style={{ marginLeft: "50%", marginTop: "15%" }}
      />
    );

    if (this.state.siopsByArea)
      image = (
        <ImageMapping
          handleHover={this.handleHover}
          handleClick={this.handleClick}
          handlePopover={this.handlePopover}
        />
      );

    let recentSIOPList = (
      <StarProgress
        color="primary"
        style={{ marginLeft: "50%", marginTop: "15%" }}
      />
    );

    var actionClicked = (id, operation, languageId) => {
      this.props.history.push(
        `/onePager?incidentID=${id}&languageID=${languageId}`
      );
    };

    if (!this.state.loadingRecent)
      recentSIOPList = (
        <Grid item xs>
          <Typography variant="h2">Recent SIOPs</Typography>
          <IncidentListTable
            actionClicked={actionClicked}
            tableContent={this.state.recentSIOPs}
            totalRecord={5}
          />
        </Grid>
      );

    return (
 <Box m={1}>
        <Typography variant="h3">Welcome {this.props.user}</Typography>
        <Typography ref={this.divRef}>{textMessage}</Typography>
        <Grid container>
          <Grid item xs={12}>
        {image}
            <Popover
              id={"simple"}
              open={this.state.popoverShow}
              anchorEl={this.state.anchorElement}
              onClose={this.handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: -300,
                horizontal: 400,
              }}
            >
              <AreaPopover
                handleHover={this.handleHover}
                handleClick={this.handleClick}
              />
            </Popover>
            </Grid>
        </Grid>
        <Grid container>
        <div id='recentsiop'>
          <Grid item xs={12}>
           {recentSIOPList}
          </Grid>
          </div>
        </Grid>
        <CustomAlerts
          snackBarOpen={this.state.snackBarOpen}
          snackBarStatus={this.state.snackBarStatus}
          snackBarText={this.state.snackBarMessage}
          handleSnackBarClose={this.handleSnackBarClose}
        />
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    search: (search) => {
      dispatch({ type: "SEARCH_AREA", value: search });
    },
    reference: (referenceData) => {
      dispatch({ type: "REFERENCE_UPD", referenceData: referenceData });
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Home));