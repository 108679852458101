import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import ImageRenderer from "./ImageRenderer";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@equinor/eds-core-react";
import aboutSIOP from "../../documentation/aboutSIOP.md";
import loginAndAccess from "../../documentation/loginAndAccess.md";
import siopHome from "../../documentation/siopHome.md";
import siopCreate from "../../documentation/siopCreate.md";
import siopApprove from "../../documentation/siopApprove.md";
import ButtonGroup from "@material-ui/core/ButtonGroup";

class UserManual extends Component {
  constructor(props) {
    super(props);

    this.state = { userManual: null };
  }

  componentDidMount() {
    fetch(aboutSIOP)
      .then((response) => response.text())
      .then((text) => {
        this.aboutSiopDetails = text;
        this.setState({ userManual: this.aboutSiopDetails });
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(loginAndAccess)
      .then((response) => response.text())
      .then((text) => {
        this.loginAndAccessDetails = text;
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(siopHome)
      .then((response) => response.text())
      .then((text) => {
        this.siopHomeDetails = text;
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(siopCreate)
      .then((response) => response.text())
      .then((text) => {
        this.siopCreateDetails = text;
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(siopApprove)
      .then((response) => response.text())
      .then((text) => {
        this.siopApproveDetails = text;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick = (selection) => {
    if (selection === "about")
      this.setState({ userManual: this.aboutSiopDetails });
    if (selection === "login")
      this.setState({ userManual: this.loginAndAccessDetails });
    if (selection === "home")
      this.setState({ userManual: this.siopHomeDetails });
    if (selection === "create")
      this.setState({ userManual: this.siopCreateDetails });
    if (selection === "approve")
      this.setState({ userManual: this.siopApproveDetails });
  };

  imageURIchange = (input) => {
    return input.replace("../../public/", process.env.PUBLIC_URL);
  };

  render() {
    var approve = <div></div>;
    var create = <div></div>;

    if (
      this.props.roles.find((role) => {
        return role === "Approve";
      })
    )
      approve = (
        <Grid item xs>
          <Button onClick={() => this.handleClick("approve")}>Approve</Button>
        </Grid>
      );

    if (
      this.props.roles.find((role) => {
        return role === "Create" || role === "Approve";
      })
    )
      create = (
        <Grid item xs>
          <Button onClick={() => this.handleClick("create")}>Create</Button>
        </Grid>
      );

    return (
      <React.Fragment>
        <Box m={2}>
          <Box m={2}>
            <Grid container>
              <Typography>
                Select the relevant sections of user manual
              </Typography>
            </Grid>
          </Box>
          <Box m={2}>
            <Grid container>
              <Typography style={{ alignSelf: "center" }}>Sway link</Typography>
              <ButtonGroup variant="text">
                <Button href="https://sway.office.com/12ThD2dZ2wCKe4KX?ref=email" style={{ alignSelf: "center"} }>
                  EN
                </Button>
                <Button href="https://sway.office.com/Y8mLaUNBsw6jyWjy?ref=email" style={{ alignSelf: "center", paddingLeft:"2px"}}>
                  NOR
                </Button>
              </ButtonGroup>
            </Grid>
          </Box>
          <Box m={2}>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => this.handleClick("about")}>
                  About SIOP
                </Button>
              </Grid>
              <Grid item xs>
                <Button onClick={() => this.handleClick("login")}>
                  Login and Access
                </Button>
              </Grid>
              <Grid item xs>
                <Button onClick={() => this.handleClick("home")}>
                  Home and Search
                </Button>
              </Grid>
              {create}
              {approve}
            </Grid>
          </Box>
        </Box>
        <Box m={5}>
          <Paper elevation={7}>
            <Box m={3}>
            <Typography style={{fontFamily:"Equinor"}}>
              <ReactMarkdown
                children={this.state.userManual}
                components={{ image: ImageRenderer }}
                transformImageUri={this.imageURIchange}
              />
              </Typography>
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    );
  }
}

export default UserManual;
/* The below line works without images
<ReactMarkdown children={this.state.terms}  />
*/
