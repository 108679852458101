import React, { Component } from "react";
import EventListTable from "./EventListTable";
import { apiURLList, apiCall } from "../../_axios";
import CustomAlerts from "../../../components/CustomAlerts";
import { StarProgress } from "@equinor/eds-core-react";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import EventSearchBar from "../../SearchAndFilter/EventSearchBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { routingPath } from "../../../Constant";
import { cloneDeepWith } from "lodash";

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableContent: [],
      tableLoading: true,
      chartContent: [],
      chartLoading: true,
      loading: true,
      dialogOpen: false,
      snackBarOpen: false,
      recordsPerPage: 5,
      totalPages: 1,
      //searchToggle is toggled when reset is pressed in searchbar.
      searchToggle: false,
      eventChartType: "",
    };
  }

  componentDidMount = async () => {
    //calling api to fetch all incidents from database when page loads for the first time.

    if (this.props.referenceData.areaLst === undefined) {
      let referenceResponseData = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.referenceData,
        "get"
      );

      if (referenceResponseData.status === 200) {
        this.props.reference(referenceResponseData.data);
      } else
        this.setState({
          snackBarOpen: true,
          snackBarMessage: referenceResponseData.message,
          snackBarStatus: "error",
        });
    }
    //this.fetchIncidents();
    this.loadChartDetailsOnMount();
  };

  componentDidUpdate = (prevProps, prevState) => {
    //this line compares the present page # and previous page # and avoids infinite loop to occur.
    //https://reactjs.org/docs/react-component.html#componentdidupdate

    if (
      this.props.pageNumber !== prevProps.pageNumber ||
      //this.state.recordsPerPage !== prevState.recordsPerPage ||
      this.state.searchToggle !== prevState.searchToggle ||
      this.state.eventChartType !== prevState.eventChartType
    ) {
      this.fetchIncidents();
    }
  };

  loadChartDetailsOnMount = async () => {
    this.setState({
      chartLoading: true,
      tableLoading: true,
    });

    let apiResponseWp = {};
    let apiResponseBarrier = {};

    if (this.props.accessToken) {
      apiResponseWp = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.allSiopEventSearch,
        "get",
        {
          params: {
            type: "Work",
          },
        }
      );

      apiResponseBarrier = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.allSiopEventSearch,
        "get",
        {
          params: {
            type: "Timp",
          },
        }
      );

      if (
        (apiResponseWp.status === 200 || apiResponseWp.status === 204) &&
        (apiResponseBarrier.status === 200 || apiResponseBarrier === 204)
      ) {
        //for 204 status, set the table content to empty, for 200, set to data sent from db
        let content;
        let chartContentArray = [];
        if (apiResponseWp.status === 200) {
          content = this.tableContentFilter(apiResponseWp.data.eventList);
          chartContentArray.push(apiResponseWp.data.eventCount);
        } else {
          content = [];
        }

        if (apiResponseBarrier.status === 200) {
          chartContentArray.push(apiResponseBarrier.data.eventCount);
        }

        this.setState({
          tableContent: content,
          chartContentArray: chartContentArray,
          loading: false,
          chartLoading: false,
          tableLoading: false,
        });
      } else {
        this.setState({
          tableContent: [],
          chartContent: [],
          snackBarOpen: true,
          snackBarMessage: apiResponseWp.message,
          snackBarStatus: "error",
          loading: false,
          chartLoading: false,
          tableLoading: false,
        });
      }
    } else {
      console.log("accessToken not available, hence skipping axios get call");
    }
  };

  //function to call api to fetch all incidents and set the state of component with those incidents.
  fetchIncidents = async () => {
    this.setState({
      chartLoading: true,
      tableLoading: true,
    });

    let apiResponse = {};
    let parameter = {};

    //send type as Work or Timp as demanded by API requirement
    if (this.props.searchParameter.workProcess.length !== 0) {
      parameter = {
        type: "Work",
      };
    }

    if (this.props.searchParameter.timpBarrier.length !== 0) {
      parameter = {
        type: "Timp",
      };
    }

    parameter = this.addSearchKeywork(parameter);

    if (this.props.accessToken) {
      apiResponse = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.allSiopEventSearch,
        "get",
        {
          params: parameter,
        }
      );

      if (apiResponse.status === 200 || apiResponse.status === 204) {
        //for 204 status, set the table content to empty, for 200, set to data sent from db
        let content;
        let chartContent;
        if (apiResponse.status === 200) {
          content = this.tableContentFilter(apiResponse.data.eventList);
          chartContent = apiResponse.data.eventCount;
        } else {
          content = [];
        }

        this.setState({
          tableContent: content,
          chartContent: chartContent,
          loading: false,
          chartLoading: false,
          tableLoading: false,
        });
      } else {
        this.setState({
          tableContent: [],
          chartContent: [],
          snackBarOpen: true,
          snackBarMessage: apiResponse.message,
          snackBarStatus: "error",
          loading: false,
          chartLoading: false,
          tableLoading: false,
        });
      }
    } else {
      console.log("accessToken not available, hence skipping axios get call");
    }
  };

  onRadioButtonChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    this.onReset();
  };

  actionClicked = (id, operation, languageId) => {
    if (operation === "onePager") {
      this.props.history.push(
        `/onePager?incidentID=${id}&languageID=${languageId}`
      );
    } else {
      this.props.history.push({
        pathname: routingPath.CREATE + "/" + id,
        state: {
          id: id,
          viewStatus: true,
          approverCommentStatus: false,
        },
      });
    }
  };

  addSearchKeywork = (parameter) => {
    //filling json with search values entered by user
    //either workprocess or timp barrier to be searched
    if (this.props.searchParameter.workProcess.length !== 0) {
      parameter.Desc = this.deepCopyObject(
        this.props.searchParameter.workProcess
      );
    }

    if (this.props.searchParameter.timpBarrier.length !== 0) {
      parameter.Desc = this.deepCopyObject(
        this.props.searchParameter.timpBarrier
      );
    }

    return parameter;
  };

  deepCopyObject = (input) => {
    return cloneDeepWith(input);
  };

  pushTextFieldToSearchKeyword = (
    searchParameterKeyword,
    keyWordForApi,
    identifier,
    textIdentifier
  ) => {
    if (searchParameterKeyword !== "") {
      //else, check whether the key types is already present in array to search and if not, then search with the text
      if (!keyWordForApi.includes(searchParameterKeyword)) {
        keyWordForApi.push(searchParameterKeyword);
        this.props.searchUpd({
          ...this.props.searchParameter,
          [identifier]: keyWordForApi,
          [textIdentifier]: "",
        });
      }
    }
  };

  snackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  //handle on page change in pagination bar
  onPageChange = (event, value) => {
    this.props.pageUpd(value);
  };

  //handle yearSlider changes
  onYearRangeChange = (newRange) => {
    this.props.searchUpd({
      ...this.props.searchParameter,
      yearRange: newRange,
    });
  };

  //handling drop down from filter
  onSearchKeyword = (keyword, identifier) => {
    this.props.searchUpd({
      ...this.props.searchParameter,
      workProcess: identifier === "wProcess" ? keyword : [],
      timpBarrier: identifier === "timp" ? keyword : [],
    });
  };

  //handle on enter and clear the text field pointed by keyword in second parameter
  onSearchValueOnEnter = (event, keyWord) => {
    if (event.charCode === 13) {
      let value = this.props.searchParameter[event.target.name];
      value.push(event.target.value);

      this.props.searchUpd({
        ...this.props.searchParameter,
        [event.target.name]: value,
        [keyWord]: "",
      });
    }
  };

  //handle chip delete
  onHandleDelete = (value, name) => {
    //get the array and filter out the value removed from chip
    let stateValue = this.props.searchParameter[name];
    stateValue = stateValue.filter((item) => item !== value);

    //reset with rest of values
    this.props.searchUpd({
      ...this.props.searchParameter,
      [name]: stateValue,
    });
  };

  onReset = () => {
    this.props.searchReset();
    this.setState((prevState) => ({
      searchToggle: !prevState.searchToggle,
      chartContent: [],
    }));
  };

  //to store text field entered in a state
  onTextChange = (event, keyWord) => {
    this.props.searchUpd({
      ...this.props.searchParameter,
      [keyWord]: event.target.value,
    });
  };

  onSearch = () => {
    this.fetchIncidents();
  };

  tableContentFilter = (content) => {
    //event type id; 1 is timp and 2 is wp
    let filteredContent = [];
    content.forEach((content) => {
      if (
        this.state.eventChartType === "" ||
        this.state.eventChartType === "Work"
      ) {
        if (content.eventTypeId === 2) {
          filteredContent.push(content);
        }
      }

      if (this.state.eventChartType === "Timp") {
        if (content.eventTypeId === 1) {
          filteredContent.push(content);
        }
      }
    });

    return filteredContent;
  };

  render() {
    var paginationJSX =
      this.state.tableContent.length !== 0 ? (
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item>
            <Pagination
              count={this.state.totalPages}
              page={this.props.pageNumber}
              onChange={this.onPageChange}
              color="primary"
            />
          </Grid>
        </Grid>
      ) : null;

    //searchbar functionality jsx
    var searchBarJSX = (
      <EventSearchBar
        accessToken={this.props.accessToken}
        searchParams={this.props.searchParameter}
        handleDelete={(value, keynameInState) =>
          this.onHandleDelete(value, keynameInState)
        }
        searchValueOnEnter={(event, keyWord) =>
          this.onSearchValueOnEnter(event, keyWord)
        }
        textChange={(event, keyword) => this.onTextChange(event, keyword)}
        //valueChange={this.onValueChange}
        searchKeyword={(keyword, identifier) =>
          this.onSearchKeyword(keyword, identifier)
        }
        search={() => {
          this.props.searchFetch();
          this.onSearch();
        }}
        reset={this.onReset}
        referenceData={this.props.referenceData}
        yearRangeChange={this.onYearRangeChange}
        chartContent={this.state.chartContent}
        chartContentArray={this.state.chartContentArray}
        //passing eventType for chart display
        reduxwpvalue={this.props.searchParameter.workProcess.length}
        reduxtimpvalue={this.props.searchParameter.timpBarrier.length}
        chartLoading={this.state.chartLoading}
        eventChartType={this.state.eventChartType}
        radioButtonChange={this.onRadioButtonChange}
        tableLength={this.state.tableContent.length}
      />
    );

    var alertJSX = (
      <CustomAlerts
        snackBarOpen={this.state.snackBarOpen}
        snackBarText={this.state.snackBarMessage}
        snackBarStatus={this.state.snackBarStatus}
        handleSnackBarClose={this.snackBarClose}
      />
    );

    var incidentListJSX = (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: "30px",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            {searchBarJSX}
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Box m={5}>
              <Typography>
                Total count : {this.state.tableContent.length}
              </Typography>
            </Box>
            {this.state.tableLoading ? (
              <StarProgress
                color="primary"
                style={{ marginLeft: "50%", marginTop: "5%" }}
              />
            ) : this.state.tableContent.length !== 0 ? (
              <EventListTable
                actionClicked={this.actionClicked}
                tableContent={this.state.tableContent}
                path={this.props.location.pathname}
                eventType={this.state.eventChartType}
              />
            ) : null}
            {/* {paginationJSX} */}
            {alertJSX}
          </Grid>
        </Grid>
      </React.Fragment>
    );

    //Load data when available, else show spinner
    if (this.state.loading) {
      incidentListJSX = (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
          {alertJSX}
        </React.Fragment>
      );
    }
    return incidentListJSX;
  }
}

const mapStateToProps = (state) => {
  return {
    searchParameter: state.searchParameter,
    pageNumber: state.pageNumber,
    referenceData: state.referenceData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchReset: () => {
      dispatch({ type: "SEARCH_RESET" });
    },
    searchUpd: (searchParameter) => {
      dispatch({ type: "SEARCH_UPDATED", searchParameter: searchParameter });
    },
    pageUpd: (pageNumber) => {
      dispatch({ type: "PAGE_CHANGED", pageNumber: pageNumber });
    },
    reference: (referenceData) => {
      dispatch({ type: "REFERENCE_UPD", referenceData: referenceData });
    },
    searchFetch: () => {
      dispatch({ type: "SEARCH_FETCH" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
