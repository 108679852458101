import React from "react";
import "./Navbar.css";
import Nav from "react-bootstrap/Nav";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import ButtonMUI from "@material-ui/core/Button";
import { Button } from "@equinor/eds-core-react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import { routingPath } from "../../Constant";
import Popover from "@material-ui/core/Popover";
import Link from "@material-ui/core/Link";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuBookOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
  },
});

const NavbarHeader = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let handleNavigation = (path) => {
    if (path === "Home") history.push(routingPath.HOME);
    if (path === "allSiopSearch") history.push(routingPath.ALL_SIOP);
    //if (path === "allSiopEventSearch") history.push(routingPath.ALL_SIOP_EVENTS);
    if (path === "mySIOPs") history.push(routingPath.MY_SIOP);
    if (path === "createSIOP") history.push(routingPath.CREATE);
    if (path === "approveSIOP") history.push(routingPath.APPROVAL_LIST);
    if (path === "UserManual") history.push(routingPath.USER_MANUAL);
  };

  let homeButton = (
    <ButtonMUI
      onClick={() => {
        handleNavigation("Home");
      }}
    >
      Home
    </ButtonMUI>
  );
  let userManualButton = (
    <React.Fragment>
      <Tooltip title="User Manual" >
        <Button
          variant="ghost"
          onClick={() => {
            handleNavigation("UserManual");
          }}
        >
         <MenuBookOutlined/>
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography>
          For feedbacks and suggestions, reach out to us by filling out this{" "}
          <Link
            rel="noopener"
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=NaKkOuK21UiRlX_PBbRZsMBL31n_qCZKlXTL6OwZUfJUMDM0U1FKRk1EWUNBMjBTRDAzTjZISExERC4u"
            color="primary"
            variant="h6"
            underline="always"
          >
            form.
          </Link>
        </Typography>
        <Typography>
          To report issues with the application, use service now.
        </Typography>
      </Popover>
    </React.Fragment>
  );

  let incidentButton = (
    <ButtonMUI
      onClick={() => {
        handleNavigation("allSiopSearch");
      }}
    >
      Search
    </ButtonMUI>
  );
  let powerbiButton = (
    <ButtonMUI
      onClick={() => {
        handlepowerbi()
      
      }}
    >
      Power BI Report
    </ButtonMUI>
  );
  // let eventSearchButton = (
  //   <ButtonMUI
  //     onClick={() => {
  //       handleNavigation("allSiopEventSearch");
  //     }}
  //   >
  //     Search Events
  //   </ButtonMUI>
  // );

  // a single drop down button to choose either incident or event search
  //let eventSearchButton = <SearchSplitButton handleNav={(path) => handleNavigation(path)}/>;
 
  let userIncidentButton = (
    <ButtonMUI
      onClick={() => {
        handleNavigation("mySIOPs");
      }}
    >
      My SIOP(s)
    </ButtonMUI>
  );

  let createButton = (
    <ButtonMUI
      onClick={() => {
        handleNavigation("createSIOP");
      }}
    >
      Create SIOP
    </ButtonMUI>
  );

  let approveButton = (
    <ButtonMUI
      onClick={() => {
        handleNavigation("approveSIOP");
      }}
    >
      Approve SIOP(s)
    </ButtonMUI>
  );
  const handlepowerbi=()=>{
    window.open(process.env.REACT_APP_POWERBI_REPORT);
    
   }
  //default access to everyone without create or approve access
  let navLink = (
    <Nav className="mr-auto">
      <Grid item xs={11}>
        {homeButton}
        {incidentButton}
        {/* {eventSearchButton} */}
       {powerbiButton}
      </Grid>
      <Grid item xs={1} alignitems="right">
        {userManualButton}
      </Grid>
    </Nav>
  );

  if (
    props?.roles?.find((role) => {
      return role === "Create";
    })
  ) {
    navLink = (
      <Nav className="mr-auto">
        <Grid item xs={11}>
          {homeButton}
          {incidentButton}
          {/* {eventSearchButton} */}
          {userIncidentButton}
          {createButton}
          {powerbiButton}
        </Grid>
        <Grid item xs={1} alignitems="right">
          {userManualButton}
        </Grid>
      </Nav>
    );
  }
  if (
    props?.roles?.find((role) => {
      return role === "Approve";
    })
  ) {
    navLink = (
      <Nav className="mr-auto">
        <Grid item xs={11}>
          {homeButton}
          {incidentButton}
          {/* {eventSearchButton} */}
          {userIncidentButton}
          {createButton}
          {approveButton}
          {powerbiButton}
        </Grid>
        <Grid item xs={1} alignitems="right">
          {userManualButton}
        </Grid>
      </Nav>
    );
  }

  return (
    <AppBar position="sticky" className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={2}>
          <img
            src="https://cdn.eds.equinor.com/logo/equinor-logo-primary.svg#red"
            alt="Equinor"
            width="150"
            height="90"
          />
        </Grid>
        <Grid item lg={10}>
          {/* <Grid item md={12} className="navbar-brand navbarText">SIOP</Grid> */}
          <Typography
            variant="h4"
            color="primary"
            style={{ marginBottom: "0px" }}
          >
            SIOP – Safety Investigation One-Pagers
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginTop: "0px", marginBottom: "5px" }}
          >
            Learn from experience
          </Typography>
           <Grid item xs={12}>
            <Box m={1}>{navLink}</Box>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default NavbarHeader;
