import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
// import Button from "@material-ui/core/Button";
import Media from "../Media/Media";
import { Chip, Typography, Accordion, Divider } from "@equinor/eds-core-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const incident = (props) => {
  //filling area list from reference data
  let areaList;
  let lifeSavingRuleList;

  if (props.referenceData.areaLst !== undefined) {
    areaList = props.referenceData.areaLst.map((areaListObj) => {
      return (
        <MenuItem key={areaListObj.id} value={areaListObj.id}>
          {areaListObj.name}
        </MenuItem>
      );
    });
  } else {
    areaList = null;
  }

  if (props.referenceData.lifeSavingRuleLst !== undefined) {
    lifeSavingRuleList = props.referenceData.lifeSavingRuleLst.map((lsrObj) => {
      return (
        <MenuItem key={lsrObj.id} value={lsrObj.id}>
          {lsrObj.name}
        </MenuItem>
      );
    });
  } else {
    console.warn(
      "Event.js; Life saving rule list is empty. Check data received from api"
    );
    lifeSavingRuleList = null;
  }

  // const userInfoForMedia = () => {
  //   const video = videoDisplay();
  //   const images = imageList;
  //   if (video === null && images === null) {
  //     return <Typography color="primary">Media unavailable</Typography>;
  //   } else {
  //     return null;
  //   }
  // };

  //dont show the load from synergi button if its a view page
  //uncomment when loading from synergi functionality is required.
  // const loadSynergiButton = () => {
  //   if (props.isViewActive) {
  //     return null;
  //   } else {
  //     return (
  //       <div className="form-row">
  //         <div className="form-group col-4">
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             size="small"
  //             onClick={props.loadFromSynergi}
  //           >
  //             <LabelOutlinedIcon />
  //             Click to load data from synergi
  //           </Button>
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className="container-fluid">
      <div className="form-row">
        <div className="form-group col">
          <TextField
            disabled={props.isViewActive}
            type="number"
            id="synergi"
            name="synergi"
            label="Synergi"
            value={props.incidentDetails.synergi}
            onChange={props.fieldChange}
          />
        </div>

        <div className="form-group col">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disabled={props.isViewActive}
              variant="inline"
              size="small"
              format="dd-MMM-yyyy"
              name="date"
              label="Date"
              value={props.incidentDetails.date}
              onChange={props.dateChange}
              autoOk={true}
              maxDate={new Date()}
              animateYearScrolling={true}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className="form-group col">
          <TextField
            error={props.incidentDetails.error.country.indicator}
            helperText={props.incidentDetails.error.country.text}
            disabled={props.isViewActive}
            id="country"
            name="country"
            label="Country"
            value={props.incidentDetails.country}
            onChange={props.fieldChange}
          ></TextField>
        </div>

        <div className="form-group col">
          <TextField
            error={props.incidentDetails.error.unit.indicator}
            helperText={props.incidentDetails.error.unit.text}
            disabled={props.isViewActive}
            id="unit"
            name="unit"
            label="Unit"
            value={props.incidentDetails.unit}
            onChange={props.fieldChange}
          ></TextField>
        </div>

        <div className="form-group col">
          <TextField
            error={props.incidentDetails.error.businessArea.indicator}
            helperText={props.incidentDetails.error.businessArea.text}
            disabled={props.isViewActive}
            id="businessArea"
            name="businessArea"
            label="Business Area"
            value={props.incidentDetails.businessArea}
            onChange={props.fieldChange}
          ></TextField>
        </div>

        <div className="form-group col">
          <TextField
            disabled={props.isViewActive}
            id="area"
            name="area"
            label="Area"
            select
            value={props.incidentDetails.area}
            onChange={props.fieldChange}
          >
            <MenuItem disabled></MenuItem>
            {areaList}
          </TextField>
        </div>
      </div>

      {/* DO NOT REMOVE this line or related functions. It is a feature to be enabled in future */}
      {/* <React.Fragment>{loadSynergiButton()}</React.Fragment> */}

      <div className="form-group">
        <TextField
          error={props.incidentDetails.error.title.indicator}
          helperText={props.incidentDetails.error.title.text}
          disabled={props.isViewActive}
          required
          id="title"
          name="title"
          label="Title"
          value={props.incidentDetails.title}
          onChange={props.fieldChange}
        />
      </div>

      <div className="form-group">
        <TextField
          error={props.incidentDetails.error.description.indicator}
          helperText={props.incidentDetails.error.description.text}
          disabled={props.isViewActive}
          id="description"
          name="description"
          label="Description"
          multiline
          placeholder="Please fill in description"
          value={props.incidentDetails.description}
          onChange={props.fieldChange}
          minRows={5}
        ></TextField>
      </div>

      <div className="form-group">
        <TextField
          error={props.incidentDetails.error.keyLesson.indicator}
          helperText={props.incidentDetails.error.keyLesson.text}
          disabled={props.isViewActive}
          id="keyLesson"
          name="keyLesson"
          label="Key Lesson"
          value={props.incidentDetails.keyLesson}
          onChange={props.fieldChange}
        />
      </div>

      <div className="form-row">
        <div className="form-group col-md-3">
          <FormControlLabel
            disabled={props.isViewActive}
            name="majorAccidentPotential"
            checked={props.incidentDetails.majorAccidentPotential}
            control={<Switch color="primary" />}
            label="Major Accident Potential? "
            labelPlacement="start"
            onChange={props.checkboxChange}
          />
        </div>

        <div className="form-group col-md-1"></div>

        <div className="form-group col-md-3">
          <Typography color="disabled">Overall Potential</Typography>
          <Chip>{props.incidentDetails.overallPotential}</Chip>
        </div>

        <div className="form-group col-md-2"></div>

        <div className="form-group col-md-3">
          <TextField
            disabled={props.isViewActive}
            name="lifeSavingRule"
            label="Life Saving Rule"
            select
            value={props.incidentDetails.lifeSavingRule}
            onChange={props.fieldChange}
          >
            <MenuItem disabled></MenuItem>
            {lifeSavingRuleList}
          </TextField>
        </div>
      </div>

      <Accordion>
        <Accordion.Item isExpanded>
          <Accordion.Header>
            Click to Edit/View images and videos
          </Accordion.Header>
          <Accordion.Panel>
            {props.isViewActive === false ? (
              <div>
                <Typography variant="h6">
                  Images and Video will be shown in the one-pager document in
                  exact same order as seen below
                </Typography>
                <Divider />
              </div>
            ) : null}
            <div className="form-row" style={{ marginLeft: "100px" }}>
              <div className="form-group col-6">
                {props.displayImage1 !== null ||
                props.isViewActive === false ? (
                  <Media
                    mediaChange={props.mediaChange}
                    onDelete={props.onDelete}
                    id="image1"
                    displayImageId="displayImage1"
                    source={props.displayImage1}
                    mediaType="image/*"
                    activeView={props.isViewActive}
                  />
                ) : null}
              </div>

              <div className="form-group col-6">
                {props.displayImage2 !== null ||
                props.isViewActive === false ? (
                  <Media
                    mediaChange={props.mediaChange}
                    onDelete={props.onDelete}
                    id="image2"
                    displayImageId="displayImage2"
                    source={props.displayImage2}
                    mediaType="image/*"
                    activeView={props.isViewActive}
                  />
                ) : null}
              </div>

              <div className="form-group col-6">
                {props.displayImage3 !== null ||
                props.isViewActive === false ? (
                  <Media
                    mediaChange={props.mediaChange}
                    onDelete={props.onDelete}
                    id="image3"
                    displayImageId="displayImage3"
                    source={props.displayImage3}
                    mediaType="image/*"
                    activeView={props.isViewActive}
                  />
                ) : null}
              </div>

              <div className="form-group col-6">
                {props.displayVideo !== null || props.isViewActive === false ? (
                  <Media
                    mediaChange={props.mediaChange}
                    onDelete={props.onDelete}
                    id="video"
                    displayImageId="displayVideo"
                    source={props.displayVideo}
                    mediaType="video/*"
                    activeView={props.isViewActive}
                  />
                ) : null}
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default incident;
