import React, { Component } from "react";
import { Button, Accordion } from "@equinor/eds-core-react";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import { Typography } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import EventSearchPopoverContent from "./EventSearchPopoverContent";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chart from "../Chart/Chart";
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class EventSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: null,
      open: false,
      content: [],
      radio: "",
      selection: [],
      eventChartType: "Work",
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    //load level one data based on radio button selection
    if (prevState.radio !== this.state.radio) {
      if (this.state.radio === "wProcess") {
        this.setState({
          content: prevProps.referenceData.workProcess1Lst,
        });
      }
      if (this.state.radio === "timp") {
        this.setState({
          content: prevProps.referenceData.timpBarrier1Lst,
        });
      }
    }
  };

  //redirect chip delete functionality to parent compoenent
  handleDelete = (value, name) => () => {
    this.props.handleDelete(value, name);
  };

  handleClose = () => {
    this.setState({ anchor: null, open: false });
  };

  handleClick = (event) => {
    this.setState({
      anchor: event.currentTarget,
      open: true,
      radio: event.target.value,
    });
  };

  handleInputs = (selectedValue) => {
    this.props.searchKeyword(selectedValue, this.state.radio);

    this.setState({
      anchor: null,
      open: false,
      selection: selectedValue,
    });
  };

  onSearchReset = () => {
    this.props.reset();
    this.setState({
      radio: "",
    });
  };

  onRadioButtonSwitch = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.props.radioButtonChange(event);
  };

  render() {
    let anchorEl = this.state.anchor;

    let wProcessChipData = this.props.searchParams.workProcess.map(
      (wpObj, index) => {
        let areaSelection = wpObj;

        return (
          <Chip
            key={wpObj}
            label={areaSelection}
            //show chip delete option only for last chip
            onDelete={
              this.props.searchParams.workProcess.length === index + 1
                ? this.handleDelete(wpObj, "workProcess")
                : undefined
            }
            variant="outlined"
          />
        );
      }
    );

    let timpChipData = this.props.searchParams.timpBarrier.map(
      (timpObj, index) => {
        let lsrSelection = timpObj;

        return (
          <Chip
            key={timpObj}
            label={lsrSelection}
            //show chip delete option only for last chip
            onDelete={
              this.props.searchParams.timpBarrier.length === index + 1
                ? this.handleDelete(timpObj, "timpBarrier")
                : undefined
            }
            variant="outlined"
          />
        );
      }
    );

    return (
      <React.Fragment>
        <Accordion chevronPosition="left">
          <Accordion.Item>
            <Accordion.Header>
              <Typography>
                <SearchOutlinedIcon />
                Search
              </Typography>
            </Accordion.Header>
            <Accordion.Panel>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RadioGroup row defaultValue="Work" name="eventChartType">
                    <FormControlLabel
                      value="Work"
                      //onChange={this.props.radioButtonChange}
                      onChange={this.onRadioButtonSwitch}
                      control={<Radio />}
                      label="Work Process"
                    />
                    <FormControlLabel
                      value="Timp"
                      //onChange={this.props.radioButtonChange}
                      onChange={this.onRadioButtonSwitch}
                      control={<Radio />}
                      label="Barrier"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    paddingBottom: "0px",
                  }}
                >
                  <ButtonGroup
                    orientation="vertical"
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginTop: "20px", marginLeft: "20px" }}
                  >
                    {this.state.eventChartType === "Work" ? (
                      <Button value="wProcess" onClick={this.handleClick}>
                        Select Work Process
                      </Button>
                    ) : (
                      <Button value="timp" onClick={this.handleClick}>
                        Select Barrier
                      </Button>
                    )}
                  </ButtonGroup>

                  <Popover
                    open={this.state.open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <EventSearchPopoverContent
                      dropDownSelection={this.handleInputs}
                      processCancellation={this.handleClose}
                      levelOneList={this.state.content}
                      accessToken={this.props.accessToken}
                      identifier={this.state.radio}
                    />
                  </Popover>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    paddingBottom: "0px",
                  }}
                >
                  <Tooltip title="Reset selection">
                    <Button variant="ghost_icon" onClick={this.onSearchReset}>
                      <RotateLeftOutlinedIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Search">
                    <Button variant="ghost_icon" onClick={this.props.search}>
                      <SearchOutlinedIcon />
                    </Button>
                  </Tooltip>
                </Grid>

                {/* Display chart */}
                <Grid item xs={10} style={{ paddingTop: "10px" }}>
                  <Chart
                    chartContent={
                      // choose the initial wp and timp count array if no selection is made by user from drop down and table content is not empty
                      this.props.chartContent.length === 0 &&
                      this.props.tableLength !== 0
                        ? this.state.eventChartType === "Work"
                          ? this.props.chartContentArray[0]
                          : this.props.chartContentArray[1]
                        : this.props.chartContent
                    }
                    //passing eventType for chart display
                    chartLoading={this.props.chartLoading}
                    reduxwpvalue={this.props.reduxwpvalue}
                    reduxtimpvalue={this.props.reduxtimpvalue}
                    eventChartType={this.props.eventChartType}
                  />
                </Grid>

                {wProcessChipData.length > 0 || timpChipData.length > 0 ? (
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontStyle: "italic",
                      }}
                      gutterBottom
                    >
                      *Click on search icon <SearchOutlinedIcon /> to search
                      work process/barrier selection.
                    </Typography>
                  </Grid>
                ) : null}

                {/* display the chips */}
                <Grid item xs={12} style={{ paddingTop: "10px" }}>
                  {this.props.searchParams.workProcess.length > 0 ? (
                    <div>Work process : {wProcessChipData}</div>
                  ) : null}

                  {this.props.searchParams.timpBarrier.length > 0 ? (
                    <div>Barrier : {timpChipData}</div>
                  ) : null}
                </Grid>
              </Grid>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </React.Fragment>
    );
  }
}

export default EventSearchBar;
