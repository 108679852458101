import React from "react";
import Typography from "@material-ui/core/Typography";

const EditInstruction = () => {
  return (
    <React.Fragment>
      <Typography style={{ marginLeft: "1%" }} variant="h5">
        Edit SIOP
      </Typography>
      <Typography style={{ marginLeft: "1%" }} variant="h6">
        Please edit the incident and event(s)
      </Typography>
      <Typography style={{ marginLeft: "1%", color: "red" }} variant="h6">
        "Note! Personal information that may identify a person, directly or
        indirectly, shall not be recorded or appear in attachments!"
      </Typography>
    </React.Fragment>
  );
};

export default EditInstruction;
