import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Divider, Typography } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import HomeIcon from "@material-ui/icons/Home";
import Box from "@material-ui/core/Box";
import { apiCall, apiURLList } from "../_axios";
import { StarProgress } from "@equinor/eds-core-react";

class EventSearchPopoverContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValues: [], //stores the selections made
      dataFromService: this.props.levelOneList, //stores the data from the api first and then drills down into the array
      itemSelected: false, //true indicates that there are no more levels
      itemSelectedIndex: 0, // stores the index of the final level item that is selected
      levelFlag: true, //true indicates that L1 is the data, false would mean levels other than L1
      loading: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      dataFromService: this.props.levelOneList,
    });
  };

  handleLevelOneDropdownSelection = async (event, key) => {
    this.setState({ loading: true });
    let respo = await this.onLevelOneSelect(this.state.dataFromService[key]);

    this.setState((prevState) => {
      //pushes the selected value to the list of selections and drills the dataservice one level down
      let selections = [...prevState.selectedValues];
      selections.push(prevState.dataFromService[key]);

      return {
        dataFromService: respo.status === 200 ? respo.data : [],
        levelFlag: false,
        loading: false,
        selectedValues: selections,
      };
    });
  };

  handleDropdownSelection = (key) => {
    this.setState((prevState) => {
      //pushes the selected value to the list of selections and drills the dataservice one level down
      let selections = [...prevState.selectedValues];
      selections.push(prevState.dataFromService[key].name);

      return {
        dataFromService: prevState.dataFromService[key].value,
        selectedValues: selections,
      };
    });
  };

  handleItemSelection = (key) => {
    //updates which item is selected at the final level
    this.setState((prevState) => {
      //removes the final element if a selection from the final section has been made
      //and then pushes the new selection to the list of selections
      let selections = [...prevState.selectedValues];

      if (prevState.itemSelected) selections.pop();

      //console.log(selections);
      selections.push(prevState.dataFromService[key].name);

      return {
        itemSelected: true,
        itemSelectedIndex: key,
        selectedValues: selections,
      };
    });
  };

  //fetch further levels of work process
  onLevelOneSelect = async (textContent) => {
    //these enum values are present in event.js file
    if (this.props.identifier === "timp") {
      return await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.barrier,
        "get",
        { params: { TimpL1: textContent } }
      );
    } else if (this.props.identifier === "wProcess") {
      return await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.workProcess,
        "get",
        { params: { processL1: textContent } }
      );
    } else {
      console.warn(
        `Level one selection neither from workprocess nor from barrier. have you added extra event type?`
      );
    }
  };

  arrowContentFormat = (content) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {content}
        </Grid>
        <Grid item xs={2}>
          <ArrowRightIcon color="primary" />
        </Grid>
      </Grid>
    );
  };

  radioContentFormat = (content) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {content}
        </Grid>
        <Grid item xs={2}>
          <RadioButtonCheckedIcon color="primary" />
        </Grid>
      </Grid>
    );
  };

  render() {
    let displayElementsArray;
    if (this.state.loading) {
      return <StarProgress style={{ marginLeft: "50%", marginTop: "15%" }} />;
    } else {
      if (this.state.levelFlag) {
        displayElementsArray = this.state.dataFromService.map(
          (levelOneItem, index) => {
            let elementSelection = (
              <Typography
                style={{ borderBottom: "inset" }}
                onClick={(event) =>
                  this.handleLevelOneDropdownSelection(event, index)
                }
              >
                {this.arrowContentFormat(levelOneItem)}
              </Typography>
            );

            if (
              this.state.itemSelected &&
              this.state.itemSelectedIndex === index
            )
              elementSelection = (
                <Typography style={{ borderBottom: "inset" }}>
                  {this.radioContentFormat(levelOneItem)}
                </Typography>
              );

            return (
              <Grid container key={levelOneItem}>
                <Grid item xs={12}>
                  {elementSelection}
                </Grid>
              </Grid>
            );
          }
        );
      } else {
        displayElementsArray = this.state.dataFromService.map((one, index) => {
          let elementSelection = (
            <Typography
              onClick={() => this.handleItemSelection(index)}
              style={{ borderBottom: "inset" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {one.name}
                </Grid>
                <Grid item xs={2}>
                  <RadioButtonUncheckedIcon color="primary" />
                </Grid>
              </Grid>
            </Typography>
          );

          if (this.state.itemSelected && this.state.itemSelectedIndex === index)
            elementSelection = (
              <Typography style={{ borderBottom: "inset" }}>
                {this.radioContentFormat(one.name)}
              </Typography>
            );

          if (one.value.length > 0) {
            elementSelection = (
              <Typography
                onClick={() => this.handleDropdownSelection(index)}
                style={{ borderBottom: "inset" }}
              >
                {this.arrowContentFormat(one.name)}
              </Typography>
            );
          }

          return (
            <Grid container key={one.name}>
              <Grid item xs={12}>
                {elementSelection}
              </Grid>
            </Grid>
          );
        });
      }
    }

    return (
      <React.Fragment>
        <Box m={2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <HomeIcon color="primary" />
                {this.state.selectedValues.join(" > ")}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box m={2}>
            <Grid container>{displayElementsArray}</Grid>
          </Box>
          <Box m={2}>
            <Grid container>
              <Divider />
              <Grid item xs={6}>
                <Button
                  onClick={() =>
                    this.props.dropDownSelection(this.state.selectedValues)
                  }
                >
                  OK
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={this.props.processCancellation}>Cancel</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default EventSearchPopoverContent;
