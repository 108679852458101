const currentYear = () => {
  let d = new Date();
  return d.getFullYear();
};

const constant = {
  START_YEAR: 2007,
  END_YEAR: currentYear(),
  TEXT_LENGTH: 300,
  AUTO_SAVE_TIME: 30000,
  LANGUAGE_ENGLISH: 1,
  LANGUAGE_NORWEGIAN: 2,
  LANGUAGE_PORTUGUESE: 3,
};

export const eventCategory = {
  TIMP_BARRIER: 1,
  WORK_PROCESS: 2,
};

export const routingPath = {
  HOME: "/",
  ALL_SIOP: "/incidents",
  //ALL_SIOP_EVENTS: "/events",
  MY_SIOP: "/userIncidents",
  CREATE: "/createIncident",
  EDIT_INCIDENT: "/createIncident/:synergiId",
  APPROVAL_LIST: "/approveIncident",
  ONE_PAGER: "/onePager",
  USER_MANUAL: "/userManual",
};

export const fieldLengths = {
  title: 100,
  description: 2000,
  country: 50,
  unit: 50,
  businessArea: 50,
  keyLesson: 300,
  eventDescription: 1000,
  actionLesson: 1000,
  workProcessBarrierText: 300,
  category: 100,
  description1: 500,
  description2: 300,
  description3: 300,
};

export default constant;
