import React, { Component } from "react";
import IncidentListTable from "./IncidentListTable";
import { apiURLList, apiCall } from "../../_axios";
import CustomAlerts from "../../../components/CustomAlerts";
import { StarProgress } from "@equinor/eds-core-react";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import IncidentSearchBar from "../../SearchAndFilter/IncidentSearchBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { routingPath } from "../../../Constant";
import { cloneDeepWith } from "lodash";

class IncidentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableContent: [],
      loading: true,
      dialogOpen: false,
      snackBarOpen: false,
      recordsPerPage: 5,
      totalPages: 1,
      totalRecords: 0,
      //searchToggle is toggled when reset is pressed in searchbar.
      searchToggle: false,
    };
  }

  componentDidMount = async () => {
    //calling api to fetch all incidents from database when page loads for the first time.

    if (this.props.referenceData.areaLst === undefined) {
      let referenceResponseData = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.referenceData,
        "get"
      );

      if (referenceResponseData.status === 200) {
        this.props.reference(referenceResponseData.data);
      } else
        this.setState({
          snackBarOpen: true,
          snackBarMessage: referenceResponseData.message,
          snackBarStatus: "error",
        });
    }
    this.fetchIncidents();
  };

  componentDidUpdate = (prevProps, prevState) => {
    //this line compares the present page # and previous page # and avoids infinite loop to occur.
    //https://reactjs.org/docs/react-component.html#componentdidupdate

    if (
      this.props.pageNumber !== prevProps.pageNumber ||
      //this.state.recordsPerPage !== prevState.recordsPerPage ||
      this.state.searchToggle !== prevState.searchToggle
    ) {
      this.fetchIncidents();
    }
  };

  //function to call api to fetch all incidents and set the state of component with those incidents.
  fetchIncidents = async () => {
    let apiResponse = {};
    let parameter = {
      pageNumber: this.props.pageNumber,
      recordsPerPage: this.state.recordsPerPage,
    };

    parameter = this.addSearchKeywork(parameter);

    if (this.props.accessToken) {
      apiResponse = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.allSiopSearch,
        "get",
        {
          params: parameter,
        }
      );

      if (apiResponse.status === 200 || apiResponse.status === 204) {
        //for 204 status, set the table content to empty, for 200, set to data sent from db
        let content;
        if (apiResponse.status === 200) {
          content = apiResponse.data.incidents;
        } else {
          content = [];
        }

        this.setState({
          tableContent: content,
          loading: false,
          totalPages: apiResponse.data.pagingModel.totalPages,
          totalRecords: apiResponse.data.pagingModel.totalRecords,
        });
      } else {
        this.setState({
          tableContent: [],
          totalRecords: 0,
          snackBarOpen: true,
          snackBarMessage: apiResponse.message,
          snackBarStatus: "error",
        });
      }
    } else {
      console.log("accessToken not available, hence skipping axios get call");
    }
  };

  actionClicked = (id, operation, languageId) => {
    if (operation === "onePager") {
      this.props.history.push(
        `/onePager?incidentID=${id}&languageID=${languageId}`
      );
    } else {
      this.props.history.push({
        pathname: routingPath.CREATE + "/" + id,
        state: {
          id: id,
          viewStatus: true,
          approverCommentStatus: false,
        },
      });
    }
  };

  addSearchKeywork = (parameter) => {
    //filling json with search values entered by user
    if (this.props.searchParameter.area.length !== 0) {
      parameter.Area_ID = this.deepCopyObject(this.props.searchParameter.area);
    }
    if (this.props.searchParameter.discipline.length !== 0) {
      parameter.Discipline_ID = this.deepCopyObject(
        this.props.searchParameter.discipline
      );
    }
    if (this.props.searchParameter.lifeSavingRule.length !== 0) {
      parameter.Life_Saving_Rule_ID = this.deepCopyObject(
        this.props.searchParameter.lifeSavingRule
      );
    }
    if (this.props.searchParameter.businessArea.length !== 0) {
      parameter.Business_Area = this.deepCopyObject(
        this.props.searchParameter.businessArea
      );
    }
    //this code block takes the text typed in fields and adds to search
    //create a new array if not create already.
    if (parameter.Business_Area === undefined) {
      //this is a refernce type copy and hence whatever changes in parameter.Business_Area affects searchParameter.businessArea as well.
      parameter.Business_Area = this.deepCopyObject(
        this.props.searchParameter.businessArea
      );
    }
    this.pushTextFieldToSearchKeyword(
      this.props.searchParameter.businessAreaText,
      parameter.Business_Area,
      "businessArea",
      "businessAreaText"
    );

    if (this.props.searchParameter.country.length !== 0) {
      //this is a refernce type copy and hence whatever changes in parameter.Country affects searchParameter.country as well.
      //parameter.Country = this.props.searchParameter.country;
      parameter.Country = this.deepCopyObject(
        this.props.searchParameter.country
      );
    }
    //this code block takes the text typed in fields and adds to search
    if (parameter.Country === undefined) {
      //parameter.Country = this.props.searchParameter.country;
      parameter.Country = this.deepCopyObject(
        this.props.searchParameter.country
      );
    }
    this.pushTextFieldToSearchKeyword(
      this.props.searchParameter.countryText,
      parameter.Country,
      "country",
      "countryText"
    );

    if (this.props.searchParameter.unit.length !== 0) {
      //this is a refernce type copy and hence whatever changes in Unit_Name affects unit as well.
      parameter.Unit_Name = this.deepCopyObject(
        this.props.searchParameter.unit
      );
    }
    //this code block takes the text typed in fields and adds to search
    if (parameter.Unit_Name === undefined) {
      parameter.Unit_Name = this.deepCopyObject(
        this.props.searchParameter.unit
      );
    }
    this.pushTextFieldToSearchKeyword(
      this.props.searchParameter.unitText,
      parameter.Unit_Name,
      "unit",
      "unitText"
    );

    if (this.props.searchParameter.category.length !== 0) {
      //this is a refernce type copy and hence whatever changes in Category affects category as well.
      parameter.Category = this.deepCopyObject(
        this.props.searchParameter.category
      );
    }
    //this code block takes the text typed in fields and adds to search
    if (parameter.Category === undefined) {
      parameter.Category = this.deepCopyObject(
        this.props.searchParameter.category
      );
    }
    this.pushTextFieldToSearchKeyword(
      this.props.searchParameter.categoryText,
      parameter.Category,
      "category",
      "categoryText"
    );

    if (this.props.searchParameter.yearRange.length !== 0) {
      //yyyy-mm-dd format
      const startYear = this.deepCopyObject(
        this.props.searchParameter.yearRange[0]
      );
      const endYear = this.deepCopyObject(
        this.props.searchParameter.yearRange[1]
      );
      parameter.StartDate = `${startYear}-01-01`;
      parameter.EndDate = `${endYear}-12-31`;
    }

    if (this.props.searchParameter.customSearch !== "") {
      parameter.GeneralSearch = this.deepCopyObject(
        this.props.searchParameter.customSearch
      );
    }

    return parameter;
  };

  deepCopyObject = (input) => {
    return cloneDeepWith(input);
  };

  pushTextFieldToSearchKeyword = (
    searchParameterKeyword,
    keyWordForApi,
    identifier,
    textIdentifier
  ) => {
    if (searchParameterKeyword !== "") {
      //else, check whether the key types is already present in array to search and if not, then search with the text
      if (!keyWordForApi.includes(searchParameterKeyword)) {
        keyWordForApi.push(searchParameterKeyword);
        this.props.searchUpd({
          ...this.props.searchParameter,
          [identifier]: keyWordForApi,
          [textIdentifier]: "",
        });
      }
    }
  };

  snackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  //handle on page change in pagination bar
  onPageChange = (event, value) => {
    this.props.pageUpd(value);
  };

  //handle yearSlider changes
  onYearRangeChange = (newRange) => {
    this.props.searchUpd({
      ...this.props.searchParameter,
      yearRange: newRange,
    });
  };

  //handling drop down from filter
  onValueChange = (event) => {
    let value;
    if (
      event.target.name === "startDate" ||
      event.target.name === "endDate" ||
      event.target.name === "customSearch"
    ) {
      value = event.target.value;
    } else {
      value = this.props.searchParameter[event.target.name];
      value.push(event.target.value);
    }

    this.props.searchUpd({
      ...this.props.searchParameter,
      [event.target.name]: value,
    });
  };

  //handle on enter and clear the text field pointed by keyword in second parameter
  onSearchValueOnEnter = (event, keyWord) => {
    if (event.charCode === 13) {
      let value = this.props.searchParameter[event.target.name];
      value.push(event.target.value);

      this.props.searchUpd({
        ...this.props.searchParameter,
        [event.target.name]: value,
        [keyWord]: "",
      });
    }
  };

  //handle chip delete
  onHandleDelete = (value, name) => {
    //get the array and filter out the value removed from chip
    let stateValue = this.props.searchParameter[name];
    stateValue = stateValue.filter((item) => item !== value);

    //reset with rest of values
    this.props.searchUpd({
      ...this.props.searchParameter,
      [name]: stateValue,
    });
  };

  onReset = () => {
    this.props.searchReset();
    this.setState((prevState) => ({
      searchToggle: !prevState.searchToggle,
    }));
  };

  //to store text field entered in a state
  onTextChange = (event, keyWord) => {
    this.props.searchUpd({
      ...this.props.searchParameter,
      [keyWord]: event.target.value,
    });
  };

  onSearch = () => {
    this.fetchIncidents();
  };

  render() {
    var paginationJSX =
      this.state.tableContent.length !== 0 ? (
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item>
            <Pagination
              count={this.state.totalPages}
              page={this.props.pageNumber}
              onChange={this.onPageChange}
              color="primary"
            />
          </Grid>
        </Grid>
      ) : null;

    //searchbar functionality jsx
    var searchBarJSX = (
      <IncidentSearchBar
        searchParams={this.props.searchParameter}
        handleDelete={(value, keynameInState) =>
          this.onHandleDelete(value, keynameInState)
        }
        searchValueOnEnter={(event, keyWord) =>
          this.onSearchValueOnEnter(event, keyWord)
        }
        textChange={(event, keyword) => this.onTextChange(event, keyword)}
        valueChange={this.onValueChange}
        search={() => {
          this.props.searchFetch();
          this.onSearch();
        }}
        reset={this.onReset}
        referenceData={this.props.referenceData}
        yearRangeChange={this.onYearRangeChange}
      />
    );

    var alertJSX = (
      <CustomAlerts
        snackBarOpen={this.state.snackBarOpen}
        snackBarText={this.state.snackBarMessage}
        snackBarStatus={this.state.snackBarStatus}
        handleSnackBarClose={this.snackBarClose}
      />
    );
    var incidentListJSX = (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: "30px",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            {searchBarJSX}
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <Box m={5}>
              <Typography>
                Total SIOP count : {this.state.totalRecords}
              </Typography>
            </Box>
            {this.state.tableContent.length !== 0 ? (
              <IncidentListTable
                actionClicked={this.actionClicked}
                tableContent={this.state.tableContent}
                path={this.props.location.pathname}
                totalRecord={this.state.totalRecords}
              />
            ) : null}
            {paginationJSX}
            {alertJSX}
          </Grid>
        </Grid>
      </React.Fragment>
    );

    //Load data when available, else show spinner
    if (this.state.loading) {
      incidentListJSX = (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
          {alertJSX}
        </React.Fragment>
      );
    }
    return incidentListJSX;
  }
}

const mapStateToProps = (state) => {
  return {
    searchParameter: state.searchParameter,
    pageNumber: state.pageNumber,
    referenceData: state.referenceData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchReset: () => {
      dispatch({ type: "SEARCH_RESET" });
    },
    searchUpd: (searchParameter) => {
      dispatch({ type: "SEARCH_UPDATED", searchParameter: searchParameter });
    },
    pageUpd: (pageNumber) => {
      dispatch({ type: "PAGE_CHANGED", pageNumber: pageNumber });
    },
    reference: (referenceData) => {
      dispatch({ type: "REFERENCE_UPD", referenceData: referenceData });
    },
    searchFetch: () => {
      dispatch({ type: "SEARCH_FETCH" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentList);
