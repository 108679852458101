import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import registerServiceWorker from "./registerServiceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import reduxStore from "./store";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={reduxStore}>
      <App title="SIOP index" />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
registerServiceWorker();
