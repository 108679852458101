import React from "react";
import AppBar from "@material-ui/core/AppBar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import AssignmentIcon from "@material-ui/icons/Assignment";

const BottomBar = (props) => {
  //Expected props
  //bottomBarValue -  current value of bottom bar
  //onBottomNavigationChange - function to handle bottom bar selection changes and methods
  //isViewActive - if view flag is set in parent component.  If view is set, the save option is not shown
  //onePagerProgress - flag to indicate one pager generation api progress
  //isApproved - flag to highlight if the SIOP is already approved (to disable the submit for approval button)

  //handle click to one pager button on bottom bar
  let viewOnePager = (
    <BottomNavigationAction
      value="ViewOnePager"
      label="View One Pager"
      icon={<AssignmentIcon />}
    />
  );

  // let saveButton = (
  //   <BottomNavigationAction value="Save" label="Save draft" icon={<Save />} />
  // );
  let submitButton = (
    <BottomNavigationAction
      value="Submit"
      label="Submit for approval"
      icon={<ArrowRight />}
    />
  );

  if (props.isViewActive) {
    //saveButton = null;
    if (props.isApproved) {
      submitButton = null;
    }
  }

  let navigationActions = (
    <BottomNavigation
      value={props.bottomBarValue}
      onChange={(event, newValue) => {
        props.onBottomNavigationChange(event, newValue);
      }}
      showLabels
    >
      <BottomNavigationAction value="Cancel" label={props.isViewActive ? "Go back" : "Save & go back"} icon={<ArrowBackOutlinedIcon />} />
      {/* {saveButton} */}
      {submitButton}
      {viewOnePager}
    </BottomNavigation>
  );

  return (
    <AppBar
      position="sticky"
      color="primary"
      style={{ top: "auto", bottom: 0 }}
    >
      {navigationActions}
    </AppBar>
  );
};

export default BottomBar;
