import React, { Component, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import CustomAlerts from "../../components/CustomAlerts";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
/*import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";*/
import { apiURLList, apiCall } from "../_axios";
import Consequence from "../Create/Consequence/Consequence";
import { StarProgress } from "@equinor/eds-core-react";
import { Link } from "@material-ui/core";
import { Media } from "react-bootstrap";
import VideoPlayer, { VideoPlayerButton } from "./OnePagerVideo";

/*PDF viewer commented
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: 100,
    height: 100,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 10,
  },
});
// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
      <View>
        <Text>Hello World, welcome to pdf testing</Text>
      </View>
    </Page>
  </Document>
);
  <PDFViewer>
    <MyDocument />
    </PDFViewer>
    
PDF viewer comments*/

class OnePager extends Component {
  constructor(props) {
    super(props);
    this.state = { siop: "", loading: true };
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const draft = query.get("draft");
    this.languageID = query.get("languageID");
    this.incidentID = query.get("incidentID");

    let apiRequired = apiURLList.onePager;
    let apiParams = {
      incidentID: this.incidentID,
      langId: this.languageID,
    };

    if (draft) {
      apiRequired = apiURLList.siop;
      apiParams = { ID: this.incidentID };
    }

    const siop = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiRequired,
      "get",
      {
        params: apiParams,
      }
    );

    if (siop.status === 200) {
      //console.log("SIOP :", siop.data);
      this.setState({ siop: siop.data, loading: false });
    } else {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: siop.message,
        snackBarStatus: "error",
      });
    }
  };

  dateFormat = (dateInNumber) => {
    let monthArray;
    var monthArrayEnglish = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var monthArrayNorwegian = [
      "jan",
      "febr",
      "mars",
      "april",
      "mai",
      "juni",
      "juli",
      "aug",
      "sept",
      "okt",
      "nov",
      "des",
    ];

    var monthArrayPortuguese = [
      "jan",
      "fev",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agusto",
      "set",
      "out",
      "nov",
      "dez",
    ];

    //if language id is present, the nchoose among language, else just load english
    if (this.languageID) {
      if (Number(this.languageID) === 1) {
        monthArray = monthArrayEnglish;
      }
      if (Number(this.languageID) === 2) {
        monthArray = monthArrayNorwegian;
      }
      if (Number(this.languageID) === 3) {
        monthArray = monthArrayPortuguese;
      }
    } else {
      monthArray = monthArrayEnglish;
    }

    var date = dateInNumber.split("/");
    return `${monthArray[date[1] - 1]} ${date[2]}`;
  };

  snackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  //handler for going back from onepager
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    if (this.state.loading)
      return (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
          <CustomAlerts
            snackBarOpen={this.state.snackBarOpen}
            snackBarText={this.state.snackBarMessage}
            snackBarStatus={this.state.snackBarStatus}
            handleSnackBarClose={this.snackBarClose}
          />
        </React.Fragment>
      );
    else {
      const eventValue = this.state.siop.events.map((event, index) => {
        let process;

        //do not show event in one pager if switch is false
        if (event.onePagerFlag) {
          //get last value of workprocess or barrier. Get text entered by user if present
          if (
            event.workProcessBarrierText !== "" &&
            event.workProcessBarrierText !== null
          ) {
            process = event.workProcessBarrierText;
          } else {
            if (event?.eventType?.id === 1) {
              let timpArray = [
                event.timpBarrier1,
                event.timpBarrier2,
                event.timpBarrier3,
                event.timpBarrier4,
              ];
              if (timpArray.indexOf(null) !== -1) {
                process = timpArray[timpArray.indexOf(null) - 1];
              } else if (timpArray.indexOf("") !== -1) {
                process = timpArray[timpArray.indexOf("") - 1];
              } else {
                process = timpArray[timpArray.length - 1];
              }
            } else if (event.eventType.id === 2) {
              let wProcessArray = [
                event.workProcess1,
                event.workProcess2,
                event.workProcess3,
                event.workProcess4,
                event.workProcess5,
                event.workProcess6,
              ];
              if (wProcessArray.indexOf(null) !== -1) {
                process = wProcessArray[wProcessArray.indexOf(null) - 1];
              } else if (wProcessArray.indexOf("") !== -1) {
                process = wProcessArray[wProcessArray.indexOf("") - 1];
              } else {
                process = wProcessArray[wProcessArray.length - 1];
              }
            }
          }

          return (
            <TableRow
              key={index}
              style={
                index % 2 ? { background: "#d5eaf4" } : { background: "white" }
              }
            >
              <TableCell>{process}</TableCell>
              <TableCell>{event.deviationDescription}</TableCell>
              <TableCell>{event.lessons}</TableCell>
            </TableRow>
          );
        } else {
          return null;
        }
      });
      const eventTable = (
        <Table size="small">
          <TableHead>
            <TableRow style={{ background: "#d5eaf4" }}>
              <TableCell>
                <strong>Process/Barrier</strong>
              </TableCell>
              <TableCell>
                <strong>Causes</strong>
              </TableCell>
              <TableCell>
                <strong>Lesson/Action</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{eventValue}</TableBody>
        </Table>
      );

      const imageGridList = () => {
        var count = 0;
        if (this.state.siop.incidents.image1Url !== null) {
          count++;
        }
        if (this.state.siop.incidents.image2Url !== null) {
          count++;
        }
        if (this.state.siop.incidents.image3Url !== null) {
          count++;
        }

        const imageSlot1 =
          this.state.siop.incidents.image1Url !== null ? (
            <ImageListItem key={0}>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  paddingTop: "5px",
                  paddingBottom: "2px",
                }}
                src={this.state.siop.incidents.image1Url}
                alt={"no content"}
              />
            </ImageListItem>
          ) : null;

        const imageSlot2 =
          this.state.siop.incidents.image2Url !== null ? (
            <ImageListItem key={1}>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  paddingTop: "5px",
                  paddingBottom: "2px",
                }}
                src={this.state.siop.incidents.image2Url}
                alt={"no content"}
              />
            </ImageListItem>
          ) : null;

        const imageSlot3 =
          this.state.siop.incidents.image3Url !== null ? (
            <ImageListItem key={3}>
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  paddingTop: "5px",
                  paddingBottom: "2px",
                }}
                src={this.state.siop.incidents.image3Url}
                alt={"no content"}
              />
            </ImageListItem>
          ) : null;
         
      //     let url = this.state.siop.incidents.videoImage4Url
      //     const videoslot3 =
      //     this.state.siop.incidents.videoImage4Url !== null ? (
      //       <Button onClick={() => window.open(url, "_blank")}>Watch the Incident Video</Button>
      // ) : null;
          

        return (
          <Grid> <ImageList rowHeight={400} cols={count}>
          {imageSlot1}
          {imageSlot2}
          {imageSlot3}
        </ImageList>
       
      </Grid>
         
        );
      };
      const linkToCaseInSynergi = () => {
        window.open(process.env.REACT_APP_SYNERGIURL + `${this.state.siop.incidents.synergiId}`);
      };

     
        
      let url = this.state.siop.incidents.videoImage4Url
      const videoslot3 =
      this.state.siop.incidents.videoImage4Url !== null ? (
        // <Button onClick={() => window.open(`${this.state.siop.incidents.videoImage4Url}`, "_blank")}>Watch the Incident Video</Button>
 <VideoPlayer videoUrl={url}/>
        ) : null;
      return (
        <Box color="gray">
          
          <Box m={1}>
            {this.languageID !== "1" ? (
              <Grid item>
                <Typography
                  style={{
                    fontStyle: "italic",
                    fontSize: "1.2rem",
                  }}
                  gutterBottom
                >
                  *The translations are based on automatic processes. Please
                  refer the one pager in English in case of any confusion. In
                  case you feel there should be a change in the translated text,
                  please raise a ticket
                </Typography>
              </Grid>
            ) : null}
            <Paper elevation={3}>
              <Box m={2}>
                <Grid container spacing={3}>
                  <Grid item xs={0.8}>
                  <Tooltip title="Go Back" arrow>
                <Button style={{marginLeft:"-2px",width:"18px",height:"30px",marginTop:"10px"}}
                  //variant="contained"
                  onClick={this.goBack}
                >
                  <ArrowBackOutlinedIcon />
                </Button>
              </Tooltip>
                    
                  </Grid>
                  <Grid item xs={5}>
                  <Typography align="left" variant="h4" >
                      {this.state.siop.incidents.incidentTitle}
                    </Typography>
                    </Grid>
                    <Grid item xs={2}>
                  <Typography align="right" variant="h6"style={{marginTop:"12px",}}>
                      {this.state.siop.incidents.unitName}
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={2}>
                    <Typography align="center" style={{marginTop:"12px",marginLeft:"-40px"}}variant="h6">
                      {this.state.siop.incidents.incidentDate !== null
                        ? this.dateFormat(
                            this.state.siop.incidents.incidentDate
                          )
                        : null}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={} style={{marginTop:"6px"}}>Hello</Grid> */}
            
                  <Grid item xs={2.5}  style={{marginTop:"3px",marginRight:"-40px"}}>{videoslot3} </Grid>
                  
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    {imageGridList()}
                  </Grid>
                  <Grid item xs={3}>
                    <Consequence
                      consequences={this.state.siop.consequenceMatrices}
                      pathname={this.props.location.pathname}
                    />
                  </Grid>
                </Grid>

                {/* <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid> */}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      {this.state.siop.incidents.incidentDescription}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography
                        align="center"
                        style={{ background: "#fff2e9", fontWeight: "bold" }}
                      >
                        {this.state.siop.incidents.keyLesson}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>{eventTable}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography align="left">
                    Synergi Case Number : <Link href="" style={{textDecoration: 'none',color:"#007079"}} onClick={linkToCaseInSynergi}>{this.state.siop.incidents.synergiId}</Link> 
                    </Typography>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Box>
      );
    }
  }
}

export default OnePager;