import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";

const Usenavbar=({children})=>{
    const[shownavbar,setShowNavbar]=useState(false)
    const location =useLocation()
    useEffect(()=>{
        if(location.pathname === '/onePager'){
            setShowNavbar(false)
        }
        else{
            setShowNavbar(true)
        }
    },[location])
    return(
        <div>{shownavbar && children}</div>
    )
   
}
export default Usenavbar;