import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Constant from "../../Constant";

const useStyles = makeStyles({
  root: {
    marginLeft: "30px",
  },
});

const marks = [
  {
    value: Constant.START_YEAR,
    label: `${Constant.START_YEAR}`,
  },
  {
    value: Constant.END_YEAR,
    label: `${Constant.END_YEAR}`,
  },
];

function DateSlider(props) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    props.yearRangeChange(newValue);
  };

  return (
    <div className={classes.root}>
      {/* <Typography id="range-slider" variant="overline">
        Start and End Date
      </Typography> */}
      <Slider
        value={props.initialValue}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={1}
        marks={marks}
        min={Constant.START_YEAR}
        max={Constant.END_YEAR}
      />
    </div>
  );
}

export default DateSlider;
