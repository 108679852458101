import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Button } from "@equinor/eds-core-react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  root: {},
  containerStyle: {
    alignItems: "center",
    textAlign: "center",
  },
}));

function ConsequenceEdit(props) {
  const classes = useStyles();

  let categoryList;
  let categoryName;
  if (props.referenceData.consequenceCategoryLst !== undefined) {
    categoryList = props.referenceData.consequenceCategoryLst.map((consObj) => {
      if (consObj.id === props.consequences.categoryId) {
        categoryName = consObj.description;
      }
      return (
        <MenuItem key={consObj.id} value={consObj.id}>
          {consObj.description}
        </MenuItem>
      );
    });
  } else {
    categoryList = null;
  }

  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary>
          <Grid item xs={8}>
            <Typography style={{ fontWeight: "bold" }}>
              {props.consequences.categoryId ? categoryName : "Category"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {/* <FormGroup>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Switch
                    //size="small"
                    name="categoryOnePagerSwitch"
                    checked={props.consequences.categoryOnePagerSwitch}
                    onChange={props.consequenceChangeHandler}
                  />
                }
                label=""
              />
            </FormGroup> */}
          </Grid>
          <Grid item xs={2}>
            <Button onClick={props.onConsequenceDelete} variant="ghost_icon">
              <DeleteForeverOutlinedIcon />
            </Button>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} className={classes.containerStyle}>
            <Grid item md={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      //size="small"
                      name="categoryOnePagerSwitch"
                      checked={props.consequences.categoryOnePagerSwitch}
                      onChange={props.consequenceChangeHandler}
                    />
                  }
                  label="Show on One Pager"
                />
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <TextField
                name="categoryId"
                label="Category"
                select
                value={props.consequences.categoryId}
                onChange={props.consequenceChangeHandler}
              >
                <MenuItem disabled></MenuItem>
                {categoryList}
              </TextField>
            </Grid>

            {categoryName === "Others" ? (
              <Grid item md={12}>
                <TextField
                  error={props.consequences.error.category.indicator}
                  helperText={props.consequences.error.category.text}
                  name="category"
                  label="Fill in category"
                  value={props.consequences.category}
                  onChange={props.consequenceChangeHandler}
                ></TextField>
              </Grid>
            ) : null}

            <Grid item md={4}>
              <TextField
                name="actual"
                label="Actual"
                type="number"
                error={props.consequences.error.actual.indicator}
                helperText={props.consequences.error.actual.text}
                style={{ textAlign: "center" }}
                value={props.consequences.actual}
                onChange={props.consequenceChangeHandler}
              />
            </Grid>

            <Grid item md={4}></Grid>

            <Grid item md={4}>
              <TextField
                name="potential"
                label="Potential"
                type="number"
                error={props.consequences.error.potential.indicator}
                helperText={props.consequences.error.potential.text}
                style={{ textAlign: "center" }}
                value={props.consequences.potential}
                onChange={props.consequenceChangeHandler}
              />
            </Grid>

            <Grid item md={12}>
              <TextField
                name="description1"
                label="Description 1"
                value={props.consequences.description1}
                onChange={props.consequenceChangeHandler}
                error={props.consequences.error.description1.indicator}
                helperText={props.consequences.error.description1.text}
              />
            </Grid>

            <Grid item md={12}>
              <TextField
                name="description2"
                label="Description 2"
                value={props.consequences.description2}
                onChange={props.consequenceChangeHandler}
                error={props.consequences.error.description2.indicator}
                helperText={props.consequences.error.description2.text}
              />
            </Grid>

            <Grid item md={12}>
              <TextField
                name="description3"
                label="Description 3"
                value={props.consequences.description3}
                onChange={props.consequenceChangeHandler}
                error={props.consequences.error.description3.indicator}
                helperText={props.consequences.error.description3.text}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default ConsequenceEdit;
