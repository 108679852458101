import axios from "axios";
import qs from "qs";

let refreshToken;
let userAcc;

//basic instance setting
const _axios = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

_axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    //refresh the token if token has expired with status code 401
    if (error.message.includes("401")) {
      const token = await refreshToken(userAcc);

      _axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      //for get methods, there is no data body. Only params can carry data to api.
      //so, send params if method is get
      return await axiosCallFunction(
        error.config.url,
        error.config.method === "get" ? {params: error.config.params} : error.config.data,
        error.config.method
      );
    }
    return { status: error.response.status, message: error.response.data };
  }
);

export const apiURLList = {
  //allSiops: "/api/incidents/allSiops",
  //allSiopsPagination: "/api/incidents/allSiopsByPaging",
  allSiopSearch: "/api/incidents/allSiops",
  allSiopEventSearch: "/api/events/allEvents",
  approvalSiops: "/api/incidents/siopsPending",
  approverAction: "/api/incidents/approveRejectSiop" ,
  mySiops: "/api/incidents/mySiops",
  siop: "/api/incidents/siop",
  referenceData: "/api/events/referenceData",
  workProcess: "/api/events/workProcess",
  barrier: "/api/events/timpBarrier",
  synergiDetails: "/api/SynergiDetails",
  onePager: "/api/incidents/siopOnePager",
  approve: "/api/incidents/approve",
  siopsByArea: "/api/incidents/siopsByArea",
};

export const apiCall = async (
  getAccessToken,
  userAccount,
  token,
  url,
  method,
  params
) => {
  let apiResponse;
  refreshToken = getAccessToken;
  userAcc = userAccount;

  if (token) {
    _axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    apiResponse = axiosCallFunction(url, params, method);
  } else {
    apiResponse = { status: 401, message: "Authentication token is missing!" };
  }
  return apiResponse;
};

//call axios http request and return response
const axiosCallFunction = async (url, params, method) => {
  let apiResponse;

  if (method === "get") {
    let newParams = params;
    if (params !== undefined && params.params !== undefined) {
      newParams = {
        params: params.params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      };
    }
    try {
      apiResponse = await _axios.get(url, newParams);
    } catch (error) {
      apiResponse = { status: 400, message: "Error in contacting server" };
    }
  } else if (method === "delete") {
    try {
      apiResponse = await _axios.delete(url, params);
    } catch (error) {
      apiResponse = { status: 400, message: "Error in contacting server" };
    }
  } else if (method === "post") {
    try {
      apiResponse = await _axios.post(url, params);
    } catch (error) {
      apiResponse = { status: 400, message: "Error in contacting server" };
    }
  } else if (method === "put") {
    try {
      apiResponse = await _axios.put(url, params);
    } catch (error) {
      apiResponse = { status: 400, message: "Error in contacting server" };
    }
  } else apiResponse = { status: 501, message: "Unknown HTTP method" };

  //console.log("apiResponse "+url+ " : ", apiResponse);

  return apiResponse;
};

export default _axios;
