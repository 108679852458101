import React, { Component } from "react";
import IncidentList from "./IncidentList/IncidentList";
import EventList from "./EventList/EventList";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

class DataListing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pageSwitchHandler = (event, pageIdentifier) => {
    let showIncidentPageFlag = pageIdentifier === "incidentPage" ? true : false;

    //send value to redux store
    this.props.pageFlagUpdate(showIncidentPageFlag);
  };
  render() {
    let pageComponents = this.props.defaultSearchPageAsIncident ? (
      <IncidentList {...this.props} />
    ) : (
      <EventList {...this.props} />
    );

    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <ButtonGroup
              orientation="horizontal"
              variant="contained"
              size="small"
              color="primary"
              style={{ marginTop: "20px", marginLeft: "28px" }}
            >
              <Button
                style={{ marginRight: "2px" }}
                onClick={(event) =>
                  this.pageSwitchHandler(event, "incidentPage")
                }
              >
                Incident
              </Button>
              <Button
                style={{ marginRight: "-10px" }}
                onClick={(event) => this.pageSwitchHandler(event, "eventPage")}
              >
                Event
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        {pageComponents}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pageFlagUpdate: (defaultSearchPageAsIncident) => {
      dispatch({
        type: "SEARCH_PAGE_CHANGE",
        defaultSearchPageAsIncident: defaultSearchPageAsIncident,
      });
    },
  };
};

//export default DataListing;
export default connect(mapStateToProps, mapDispatchToProps)(DataListing);
