import React, { Component } from "react";
import Incident from "./Incident/Incident";
import Consequence from "./Consequence/Consequence";
import ConsequenceEdit from "./Consequence/ConsequenceEdit";
import { apiURLList, apiCall } from "../_axios";
import "./Incident/Incident.css";
import Form from "react-bootstrap/Form";
import Event from "./Event/Event";
import "./Create.css";
import { StarProgress } from "@equinor/eds-core-react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CreateInstruction from "./Instruction/CreateInstruction";
import EditInstruction from "./Instruction/EditInstruction";
import ViewInstruction from "./Instruction/ViewInstruction";
import BottomBar from "./BottomBar/BottomBar";
import { encode } from "base64-arraybuffer";
import CustomAlerts from "../../components/CustomAlerts";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import ApproverComment from "./ApproverComment/ApproverComment";
import Constant, { routingPath, fieldLengths } from "../../Constant";
import { cloneDeepWith } from "lodash";

let autoSaveTimer;

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackBarStatus: "success",
      snackBarOpen: false,
      snackBarMessage: null,
      dialogType: null,
      dialogText: null,
      dialogOpen: false,
      dialogMessageText: "",
      dialogMessageLink: "",
      dialogMessageOpen: false,
      bottomBarValue: "Cancel",
      consequenceShowStatus: false,
      isViewActive: false,
      isApproved: false,
      onePagerProgress: false,
      loading: true,
      displayImage1: "",
      displayImage2: "",
      displayImage3: "",
      displayVideo: "",
      image1InitialState: null,
      image2InitialState: null,
      image3InitialState: null,
      videoInitialState: null,
      referenceData: [],
      metadata: {
        deletedEvent: [],
        deletedConsequence: [],
        deletedCause: [],
      },
      eventFormField: {
        eventId: null,
        workProcessBarrier: "",
        workProcess1: "",
        workProcess2: "",
        workProcess3: "",
        workProcess4: "",
        workProcess5: "",
        workProcess6: "",
        timpBarrier1: "",
        timpBarrier2: "",
        timpBarrier3: "",
        timpBarrier4: "",
        workProcessBarrierText: "",
        eventOnePagerSwitch: true,
        eventDescription: "",
        actionLesson: "",
        discipline: "",
        isEdited: false,
        actionTaken: null,
        eventSeqNo: null,
        error: {
          eventDescription: { indicator: false, text: "" },
          actionLesson: { indicator: false, text: "" },
          workProcessBarrierText: { indicator: false, text: "" },
        },
      },
      consequenceFormField: {
        consequenceId: -1,
        category: "",
        categoryId: "",
        categoryOnePagerSwitch: true,
        actual: "",
        potential: "",
        description1: "",
        description2: "",
        description3: "",
        isEdited: false,
        action: null,
        error: {
          category: { indicator: false, text: null },
          actual: { indicator: false, text: null },
          potential: { indicator: false, text: null },
          description1: { indicator: false, text: null },
          description2: { indicator: false, text: null },
          description3: { indicator: false, text: null },
        },
      },
      incident: {
        incidentId: null,
        userId: this.props.userAccount.username,
        synergi: "",
        date: new Date(),
        country: "",
        unit: "",
        businessArea: "",
        area: "",
        title: "",
        description: "",
        keyLesson: "",
        approverComment: "",
        userComment: "",
        lifeSavingRule: "",
        overallPotential: "",
        majorAccidentPotential: false,
        isEdited: false,
        actionTaken: null,
        image1: {
          data: null,
          name: null,
          isEdited: false,
          action: null,
        },
        image2: {
          data: null,
          name: null,
          isEdited: false,
          action: null,
        },
        image3: {
          data: null,
          name: null,
          isEdited: false,
          action: null,
        },
        video: {
          data: null,
          name: null,
          isEdited: false,
          action: null,
        },
        error: {
          country: { indicator: false, text: "" },
          unit: { indicator: false, text: "" },
          businessArea: { indicator: false, text: "" },
          title: { indicator: false, text: "" },
          description: { indicator: false, text: "" },
          keyLesson: { indicator: false, text: "" },
        },
        consequenceList: [
          {
            consequenceId: -1,
            category: "",
            categoryId: "",
            categoryOnePagerSwitch: true,
            actual: "",
            potential: "",
            description1: "",
            description2: "",
            description3: "",
            isEdited: false,
            action: null,
            error: {
              category: { indicator: false, text: null },
              actual: { indicator: false, text: null },
              potential: { indicator: false, text: null },
              description1: { indicator: false, text: null },
              description2: { indicator: false, text: null },
              description3: { indicator: false, text: null },
            },
          },
        ],
        events: [
          {
            eventId: null,
            workProcessBarrier: "",
            workProcess1: "",
            workProcess2: "",
            workProcess3: "",
            workProcess4: "",
            workProcess5: "",
            workProcess6: "",
            timpBarrier1: "",
            timpBarrier2: "",
            timpBarrier3: "",
            timpBarrier4: "",
            workProcessBarrierText: "",
            eventOnePagerSwitch: true,
            eventDescription: "",
            actionLesson: "",
            discipline: "",
            isEdited: false,
            actionTaken: null,
            eventTypeChanged: false,
            eventSeqNo: null,
            error: {
              eventDescription: { indicator: false, text: "" },
              actionLesson: { indicator: false, text: "" },
              workProcessBarrierText: { indicator: false, text: "" },
            },
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    //fetch the reference data from db for select fields
    if (this.props.referenceData.areaLst === undefined) {
      let referenceResponseData = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.referenceData,
        "get"
      );

      if (referenceResponseData.status === 200) {
        this.props.reference(referenceResponseData.data);
      } else
        this.setState({
          snackBarOpen: true,
          snackBarMessage: referenceResponseData.message,
          snackBarStatus: "error",
        });
    }
    //load specific incident based on synergi id sent, if Create page is redirecting from incident list edit or view button
    if (this.props.location.state !== undefined) {
      this.setViewState();
      this.onLoadUserData(this.props.location.state.id, this.props.accessToken);
    } else {
      this.setState({
        loading: false,
      });
    }

    //flag is defined and set only in myincident page to prevent auto save from any other page
    //also autosave if in create page
    if (
      (this.props.location.state !== undefined &&
        this.props.location.state.autoSaveFlag !== undefined &&
        this.props.location.state.autoSaveFlag) ||
      this.props.location.pathname === routingPath.CREATE
    ) {
      autoSaveTimer = setInterval(this.handleSave, Constant.AUTO_SAVE_TIME);
    }
  };

  componentWillUnmount = () => {
    clearInterval(autoSaveTimer);
  };

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false, dialogMessageOpen: false });
  };

  handleDialogConfirmation = async () => {
    if (this.state.dialogType === "Cancel") this.props.history.goBack();
    else if (this.state.dialogType === "Submit") {
      let submitResponse = await apiCall(
        this.props.getAccessToken,
        this.props.userAccount,
        this.props.accessToken,
        apiURLList.approve,
        "post",
        {
          IncidentID: this.state.incident.incidentId,
          synergiId: parseInt(this.state.incident.synergi, 10),
          comments: this.state.incident.userComment,
        }
      );
     

      this.props.history.push({
        pathname: routingPath.MY_SIOP,
        state: {
          status: submitResponse.status === 200 ? "success" : "error",
          message:
            submitResponse.status === 200
              ? submitResponse.data
              : submitResponse.data,
        },
      });
    } else {
      this.props.history.push({
        pathname: routingPath.HOME,
        state: { status: true, message: "SIOP submitted successfully!" },
      });
    }

    this.handleDialogClose();
  };

  checkIfSaveRequired = (data) => {
    
    var  incidentCheck= false
    if (!(data.actionTaken === null)) return true;
    // if (data.incidentId > 0 && data.actionTaken === "edit" ) {
    //   if(data.incidentId === null ){ data.actionTaken = "add"}
    //   incidentCheck= true
      
    // }
    
    eventCheck =true;


    var eventCheck = false;

    data.events.forEach((element) => {
      if (!(element.actionTaken === null)) eventCheck = true;
    });


    var consequenceCheck = false;

    data.consequenceList.forEach((element) => {
      if(element.consequenceId > 0 && element.action === "edit") consequenceCheck = true
      if(element.consequenceId === -1 && element.action === "add") consequenceCheck = true
    });

    if ( incidentCheck || eventCheck || consequenceCheck ) return true;
    else return false;
  };

  handleSave = async () => {
    let saveResponse;
    var data = { ...this.state.incident };

    if (this.checkIfSaveRequired(data)) {
      var siopData = this.mapToSaveApiFormat(data);

      if (data.incidentId === null) {
        //new siop creation entry, all post
        saveResponse = await apiCall(
          this.props.getAccessToken,
          this.props.userAccount,
          this.props.accessToken,
          apiURLList.siop,
          "post",
          siopData
        );
        
      } else {
        //edit operation, calling put
        saveResponse = await apiCall(
          this.props.getAccessToken,
          this.props.userAccount,
          this.props.accessToken,
          apiURLList.siop,
          "put",
          siopData
        );
      }

      if (saveResponse.status === 200 || saveResponse.status === 206) {
        //assign consequence/event ids to newly created onces
        let events = this.state.incident.events;
        let consequences = this.state.incident.consequenceList;

        if (saveResponse.status === 200) {
          let indexCount = 0;
          events.forEach((event) => {
            event.eventId =
              saveResponse.data.eventIds.length !== 0
                ? saveResponse.data.eventIds[indexCount]
                : null;
            //resetting the action to null to avoid duplication of consequence due to add or edit action.
            //resetting edited flag to false
            event.actionTaken = null;
            event.isEdited = false;
            indexCount++;
          });
          
          indexCount = 0;
          consequences.forEach((conseq) => {
            conseq.consequenceId =
              saveResponse.data.consequenceIds.length !== 0
                ? saveResponse.data.consequenceIds[indexCount]
                : null;
                
            //resetting the action to null to avoid duplication of consequence due to add or edit action.
            //resetting edited flag to false
            conseq.action = null;
            conseq.isEdited = false;
            indexCount++;
          });
        }

        //remain on same create page
        this.setState((prevState) => ({
          incident: {
            ...prevState.incident,
            incidentId:
              saveResponse.config.method === "post"
                ? saveResponse.data.incidentId
                : prevState.incident.incidentId,
            events: events,
            consequenceList: consequences,
          },
          //clearing deleted event, consequence and cause ids
          //to avoid sending the deleted ids again.
          metadata: {
            deletedEvent: [],
            deletedConsequence: [],
            deleteCause: [],
          },
          snackBarOpen: true,
          snackBarMessage: saveResponse.data.statusText,
          snackBarStatus: "success",
          loading: false,
         
        }));
       
      } else if (saveResponse.status === 208) {
        
        this.setState({
          snackBarOpen: true,
          snackBarMessage: saveResponse.data.statusText,
          snackBarStatus: "error",
          loading: false,
        });
      } else {
        //includes for 500 status error and everything else
        this.setState({
          snackBarOpen: true,
          snackBarMessage: "Error Saving data",
          snackBarStatus: "error",
          loading: false,
        });
      }
    }
  };

  popupBlocked = (popupWindow) => {
    if (popupWindow) {
      if (/chrome/.test(navigator.userAgent.toLowerCase())) {
        try {
          popupWindow.focus();
        } catch (e) {
          return true;
        }
      } else {
        popupWindow.onload = () => {
          if (popupWindow.innerHeight <= 0) return false;
        };
      }
    } else {
      return true;
    }
    return false;
  };

  generateOnePager = async () => {
    if (this.state.incident.incidentId) {
      this.props.history.push(
        `/onePager?incidentID=${this.state.incident.incidentId}&draft=true`
      );
    } else
      this.setState({
        snackBarStatus: "error",
        snackBarOpen: true,
        snackBarMessage: "Please save the SIOP first!",
        onePagerProgress: false,
      });
  };

  //setting state to either disble or keep all input fields enabled
  //also showing consequence entries in view mode
  setViewState = () => {
    if (this.props.location.state.viewStatus) {
      const viewStatus = this.state.isViewActive;
      const consequenceStatus = this.state.consequenceShowStatus;
      this.setState((prevState) => ({
        isViewActive: !prevState.viewStatus,
        consequenceShowStatus: !prevState.consequenceStatus,
      }));
    }
  };

  onDateChangeHandler = (date) => {
    var _actionTaken = this.state.incident.incidentId === null ? "add" : "edit";
  
    this.setState({
      incident: {
        ...this.state.incident,
        date: date,
        isEdited: true,
        actionTaken: _actionTaken,
      },
     
    });
  };

  //handler for incident input field change
  onIncidentChangeHandler = (event) => {
    const value = event.target.value;

    const lengthsArray = fieldLengths;

    var maxLength = 0;

    eval("maxLength = lengthsArray." + event.target.name);

    var errorText =
      "Exceeded max limit of " +
      maxLength +
      "chars. Additional chars will be truncated.";
     
    if (event.target.name in lengthsArray) {
      
      if (event.target.value.length > maxLength)
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
        
          eval(
            "newState.incident.error." +
              event.target.name +
              ".indicator" +
              " = true"
          );
          eval(
            "newState.incident.error." +
              event.target.name +
              ".text" +
              " = errorText"
          );
          eval("newState.incident." + event.target.name + " = value");
          newState.incident.isEdited = true;
          newState.incident.actionTaken =
            newState.incident.incidentId === null ? "add" : "edit";
           //check console
          return newState;
        });
      else
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          eval(
            "newState.incident.error." +


              event.target.name +
              ".indicator" +
              " = false"
          );
          eval(
            "newState.incident.error." + event.target.name + ".text" + " = null"
          );
          eval("newState.incident." + event.target.name + " = value");
          newState.incident.isEdited = true;
          newState.incident.actionTaken =
            newState.incident.incidentId === null ? "add" : "edit";
           
          return newState;
        });
    } else {
      this.setState((prevState) => {
        var newState = cloneDeepWith(prevState);
        eval("newState.incident." + event.target.name + " = value");
        newState.incident.isEdited = true;
        newState.incident.actionTaken =
        newState.incident.incidentId === null ? "add" : "edit";
         
        return newState;
      });
    }
  };

  //checkbox handler
  onCheckboxSwitchHandler = (event) => {
    this.setState({
      incident: {
        ...this.state.incident,
        [event.target.name]: event.target.checked,
      },
    });
  };

  //handling consequence component change
  onConsequenceChangeHandler = (event, index) => {
    let consequenceValue = event.target.value;

    const lengthsArray = fieldLengths;

    var maxLength = 0;

    eval("maxLength = lengthsArray." + event.target.name);

    var errorText =
      "Exceeded max limit of " +
      maxLength +
      "chars. Additional chars will be truncated.";

    if (event.target.name in lengthsArray) {
      if (event.target.value.length > maxLength)
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          eval(
            "newState.incident.consequenceList[" +
              index +
              "].error." +
              event.target.name +
              ".indicator" +
              " = true"
          );
          eval(
            "newState.incident.consequenceList[" +
              index +
              "].error." +
              event.target.name +
              ".text" +
              " = errorText"
          );
          eval(
            "newState.incident.consequenceList[" +
              index +
              "]." +
              event.target.name +
              " = consequenceValue"
          );
          newState.incident.consequenceList[index].isEdited = true;
          newState.incident.consequenceList[index].action =
            newState.incident.consequenceList[index].consequenceId === null
              ? "add"
              : "edit";
          return newState;
        });
      else
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          eval(
            "newState.incident.consequenceList[" +
              index +
              "].error." +
              event.target.name +
              ".indicator" +
              " = false"
          );
          eval(
            "newState.incident.consequenceList[" +
              index +
              "].error." +
              event.target.name +
              ".text = null"
          );
          eval(
            "newState.incident.consequenceList[" +
              index +
              "]." +
              event.target.name +
              " = consequenceValue"
          );
          newState.incident.consequenceList[index].isEdited = true;
          newState.incident.consequenceList[index].action =
            newState.incident.consequenceList[index].consequenceId === null
              ? "add"
              : "edit";
          return newState;
        });
    } else {
      var valueMismatch = "Check if Potential < Actual";
      var valueLimits = "Allowed range (1-5)";

      if (event.target.name === "actual" || event.target.name === "potential")
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);

          newState.incident.consequenceList[index].isEdited = true;
          newState.incident.consequenceList[index].action =
            newState.incident.consequenceList[index].consequenceId === null
              ? "add"
              : "edit";

          eval(
            "newState.incident.consequenceList[" +
              index +
              "]." +
              event.target.name +
              " = consequenceValue"
          );

          if (
            newState.incident.consequenceList[index].actual <
              newState.incident.consequenceList[index].potential &&
            newState.incident.consequenceList[index].actual !== ""
          ) {
            newState.incident.consequenceList[
              index
            ].error.actual.indicator = true;
            newState.incident.consequenceList[
              index
            ].error.potential.indicator = true;
            newState.incident.consequenceList[index].error.actual.text =
              valueMismatch;
            newState.incident.consequenceList[index].error.potential.text =
              valueMismatch;
          } else {
            newState.incident.consequenceList[
              index
            ].error.actual.indicator = false;
            newState.incident.consequenceList[
              index
            ].error.potential.indicator = false;
            newState.incident.consequenceList[index].error.actual.text = null;
            newState.incident.consequenceList[index].error.potential.text =
              null;
          }

          //show warning only if potential is entered out of range
          if (newState.incident.consequenceList[index].actual !== "") {
            if (
              newState.incident.consequenceList[index].actual < 1 ||
              newState.incident.consequenceList[index].actual > 5
            ) {
              newState.incident.consequenceList[
                index
              ].error.actual.indicator = true;
              newState.incident.consequenceList[index].error.actual.text =
                valueLimits;
            } else {
              if (
                !newState.incident.consequenceList[index].error.actual.indicator
              ) {
                newState.incident.consequenceList[
                  index
                ].error.actual.indicator = false;
                newState.incident.consequenceList[index].error.actual.text =
                  null;
              }
            }
          }

          //show warning only if potential entered is out of range
          if (newState.incident.consequenceList[index].potential !== "") {
            if (
              newState.incident.consequenceList[index].potential < 1 ||
              newState.incident.consequenceList[index].potential > 5
            ) {
              newState.incident.consequenceList[
                index
              ].error.potential.indicator = true;
              newState.incident.consequenceList[index].error.potential.text =
                valueLimits;
            } else {
              if (
                !newState.incident.consequenceList[index].error.potential
                  .indicator
              ) {
                newState.incident.consequenceList[
                  index
                ].error.potential.indicator = false;
                newState.incident.consequenceList[index].error.potential.text =
                  null;
              }
            }
          }

          return newState;
        });
      else {
        if (event.target.name === "categoryOnePagerSwitch") {
          this.setState((prevState) => {
            var newState = cloneDeepWith(prevState);
            newState.incident.consequenceList[index].categoryOnePagerSwitch =
              !prevState.incident.consequenceList[index].categoryOnePagerSwitch;
            newState.incident.consequenceList[index].isEdited = true;
            newState.incident.consequenceList[index].action =
              newState.incident.consequenceList[index].consequenceId === null
                ? "add"
                : "edit";
            return newState;
          });
        } else {
          this.setState((prevState) => {
            var newState = cloneDeepWith(prevState);
            eval(
              "newState.incident.consequenceList[" +
                index +
                "]." +
                event.target.name +
                " = consequenceValue"
            );
            newState.incident.consequenceList[index].isEdited = true;
            newState.incident.consequenceList[index].action =
              newState.incident.consequenceList[index].consequenceId === null
                ? "add"
                : "edit";
            return newState;
          });
        }
      }
    }
  };

  onConsequenceAddHandler = () => {
    this.setState({
      incident: {
        ...this.state.incident,
        consequenceList: [
          ...this.state.incident.consequenceList,
          { ...this.state.consequenceFormField },
        ],
      },
    });
  };

  onConsequenceDeleteHandler = (event, index) => {
    //get all the consequences in the list, gets it id and remove the one deleted
    let currentConsequences = this.state.incident.consequenceList;
    const id = currentConsequences[index].consequenceId;
  
    //currentConsequences.splice(index, 1);
    currentConsequences[index].action = "delete";

    //store the id of deleted consequence in a metadata to be
    //sent later to api for processing
    let deletedConsequence = this.state.metadata.deletedConsequence;
    //a non null incident id means it is edit phase and not a new SIOP creation
    //a non null consequence id means it is edit phase and not a new consequence addition
  
   currentConsequences = currentConsequences.filter((consequence) => consequence.action !== "delete");
    if (this.state.incident.incidentId !== null) {
      if (id !== null) {
        deletedConsequence.push(id);
        // currentConsequences.map((deletedConsequence) => {
        // })
       
         
           currentConsequences = currentConsequences.filter((consequence) => consequence.action !== "delete");
    
      }
    }

    //set the state
    this.setState((prevState) => ({
      incident: {
        ...prevState.incident,
        consequenceList: currentConsequences,
        metadata: {
          ...prevState.metadata,
          deletedConsequence: deletedConsequence,
        },
      },
    
    }
    )
    );
    
  };

  //handler to load synergi data to SIOP UI
  onLoadFromSynergi = async (event) => {
    this.setState({ loading: true });
    const synergiData = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      this.props.accessToken,
      apiURLList.synergiDetails + "/" + this.state.incident.synergi,
      "get"
    );

    this.loadFromSynergiToView(synergiData.data);
  };

  //mapping synergi fetched from db
  loadFromSynergiToView = (data) => {
    let consequenceState = [];
    data.consequences.forEach((consequence, index) => {
      consequenceState[index] = {
        actual: consequence.actual,
        potential: consequence.potential,
        category: consequence.category,
        description1: consequence.description1,
        description2: consequence.description2,
        description3: consequence.description3,
      };
    });

    let incident = {
      ...this.state.incident,
      date: data.caseDate === null ? "" : this.dateFormat(data.caseDate),
      country: data.country,
      unit: data.unit,
      businessArea: data.businessArea,
      title: data.title,
      description: data.caseDescription,
      consequenceList: consequenceState,
    };

    this.setState({ incident: incident, loading: false });
  };

  //handler for events field change
  onEventChangeHandler = (event, index) => {
    let eventValue = event.target.value;

    const lengthsArray = fieldLengths;

    var maxLength = 0;

    eval("maxLength = lengthsArray." + event.target.name);

    var errorText =
      "Exceeded max limit of " +
      maxLength +
      "chars. Additional chars will be truncated.";

    if (event.target.name in lengthsArray) {
      if (event.target.value.length > maxLength)
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          eval(
            "newState.incident.events[" +
              index +
              "].error." +
              event.target.name +
              ".indicator = true"
          );
          eval(
            "newState.incident.events[" +
              index +
              "].error." +
              event.target.name +
              ".text = errorText"
          );
          eval(
            "newState.incident.events[" +
              index +
              "]." +
              event.target.name +
              " = eventValue"
          );
          newState.incident.events[index].isEdited = true;
          newState.incident.events[index].actionTaken =
            newState.incident.events[index].eventId === null ? "add" : "edit";
          return newState;
        });
      else
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          eval(
            "newState.incident.events[" +
              index +
              "].error." +
              event.target.name +
              ".indicator = false"
          );
          eval(
            "newState.incident.events[" +
              index +
              "].error." +
              event.target.name +
              ".text = null"
          );
          eval(
            "newState.incident.events[" +
              index +
              "]." +
              event.target.name +
              " = eventValue"
          );
          newState.incident.events[index].isEdited = true;
          newState.incident.events[index].actionTaken =
            newState.incident.events[index].eventId === null ? "add" : "edit";

          return newState;
        });
    } else {
      if (event.target.name === "eventOnePagerSwitch") {
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          newState.incident.events[index].eventOnePagerSwitch =
            !prevState.incident.events[index].eventOnePagerSwitch;
          newState.incident.events[index].isEdited = true;
          newState.incident.events[index].actionTaken =
            newState.incident.events[index].eventId === null ? "add" : "edit";
          return newState;
        });
      } else {
        this.setState((prevState) => {
          var newState = cloneDeepWith(prevState);
          eval(
            "newState.incident.events[" +
              index +
              "]." +
              event.target.name +
              " = eventValue"
          );

          if (
            prevState.incident.events[index].workProcessBarrier ===
            newState.incident.events[index].workProcessBarrier
          )
            newState.incident.events[index].eventTypeChanged = false;
          else newState.incident.events[index].eventTypeChanged = true;

          newState.incident.events[index].isEdited = true;
          newState.incident.events[index].actionTaken =
            newState.incident.events[index].eventId === null ? "add" : "edit";
          return newState;
        });
      }
    }
  };
  //change the order of event based on arrow clicked
  onReorderEvent = (direction, sequenceNo) => {
    //steps done: copy the event to move up in array(left) or down in array(right)
    //remove it after copying, then insert at new position.
    //also interchange the event sequence no so that future interchanges can be donw

    //array index is sequenceNo minus 1
    const seqNo = sequenceNo - 1;
    let eventState = [...this.state.incident.events];
    //copying event to move
    const eventToMove = eventState[seqNo];

    if (direction === "left") {
      //remove 1 from index no = seqNo
      eventState.splice(seqNo, 1);
      //insert copied event to new position
      eventState.splice(seqNo - 1, 0, eventToMove);

      //exchanging seq no as they intechanged positions
      eventToMove.eventSeqNo = eventToMove.eventSeqNo - 1;
      eventToMove.isEdited = true;
      eventToMove.actionTaken = eventToMove.eventId === null ? "add" : "edit";
      eventState[seqNo].eventSeqNo = eventState[seqNo].eventSeqNo + 1;
      eventState[seqNo].isEdited = true;
      eventState[seqNo].actionTaken =
        eventState[seqNo].eventId === null ? "add" : "edit";
    } else {
      //remove 1 from index no = seqNo
      eventState.splice(seqNo, 1);
      //insert copied event to new position
      eventState.splice(seqNo + 1, 0, eventToMove);

      //exchanging seq no as they intechanged positions
      eventToMove.eventSeqNo = eventToMove.eventSeqNo + 1;
      eventToMove.isEdited = true;
      eventToMove.actionTaken = eventToMove.eventId === null ? "add" : "edit";
      eventState[seqNo].eventSeqNo = eventState[seqNo].eventSeqNo - 1;
      eventState[seqNo].isEdited = true;
      eventState[seqNo].actionTaken =
        eventState[seqNo].eventId === null ? "add" : "edit";
    }

    this.setState({
      incident: {
        ...this.state.incident,
        events: eventState,
      },
    });
  };

  onMediaChangeHandler = (event) => {
    //create a temporary url for preview purpose
    let tempUrl = URL.createObjectURL(event.target.files[0]);
    let action = "";

    //getting initial media state from state.
    //this is required to handle editing of media when an image is added in empty slot, then deleted and again added
    //(action should not be edit in this case but should be add as slot was empty earlier)
    let mediaInitialState = null;
    if (event.target.name === "image1") {
      mediaInitialState = this.state.image1InitialState;
    } else if (event.target.name === "image2") {
      mediaInitialState = this.state.image2InitialState;
    } else if (event.target.name === "image3") {
      mediaInitialState = this.state.image3InitialState;
    } else if (event.target.name === "video") {
      mediaInitialState = this.state.videoInitialState;
    } else {
      console.log("media id not identified");
    }

    //updating action based on checking if it is create or edit.
    //IncidentId is null for create siop and has a value when being edited
    if (this.state.incident.incidentId !== null) {
      //true means mediais already present and hence it is edit phase
      if (mediaInitialState === true) {
        action = "edit";
      } else {
        //false means media is empty and is add phase
        action = "add";
      }
    } else {
      action = "add";
    }

    //converting image format api would accept so as to send it to api
    let reader = new FileReader();

    //event handler which would hit when image is loaded
    reader.onload = () => {
      this.setState((prevState) => ({
        [event.target.id]: tempUrl,
        incident: {
          ...prevState.incident,
          isEdited: true,
          //action taken for incident will be edit when media is changed/added in edit phase,
          //also, if only image is added and siop saved, take actionTaken as action taken on image
          actionTaken:
            this.state.incident.incidentId !== null
              ? "edit"
              : this.state.incident.actionTaken !== null
              ? this.state.incident.actionTaken
              : action,
          //[event.target.name]: reader.result,
          [event.target.name]: {
            data: reader.result,
            //name: event.target.files[0].name,
            name: event.target.name,
            isEdited: true,
            action: action,
          },
        },
        
      }
      )
      );
    };
   
    //converting to array buffer
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  //reset image state to default when delete pressed
  onMediaDeleteHandler = (event, mediaIdentifier, displayMediaIdentifier) => {
    let action = "";

    //getting initial state of media so that to differentiate a delete on already present media or delete on media in empty slot
    let mediaInitialState = null;
    if (mediaIdentifier === "image1") {
      mediaInitialState = this.state.image1InitialState;
    } else if (mediaIdentifier === "image2") {
      mediaInitialState = this.state.image2InitialState;
    } else if (mediaIdentifier === "image3") {
      mediaInitialState = this.state.image3InitialState;
    } else if (mediaIdentifier === "video") {
      mediaInitialState = this.state.videoInitialState;
    } else {
      console.log("media id not identified");
    }

    //add action based on media initia state
    if (this.state.incident.incidentId !== null) {
      if (mediaInitialState === true) {
        action = "delete";
      } else {
        action = null;
      }
    } else {
      action = null;
    }

    this.setState((prevState) => ({
      
      [displayMediaIdentifier]: "",
      incident: {
        ...prevState.incident,
        isEdited: true, //to set to true only if image was earlier present in edit phase
        //action taken for incident will be edit when media is changed/added in edit phase
        actionTaken:
          this.state.incident.incidentId !== null
            ? "edit"
            : this.state.incident.actionTaken,
        [mediaIdentifier]: {
          data: null,
          name: null,
          isEdited: mediaInitialState === true ? true : false, //to set to true only if image was earlier present in edit phase,
          action: action,
        },
      },
    }));
  };
  // console.log(this.state.incident.actionTaken)
  //handles add events to create page
  onAddEventHandler = () => {
    this.setState({
      incident: {
        ...this.state.incident,
        events: [
          ...this.state.incident.events,
          { ...this.state.eventFormField },
        ],
      },
    });
  };

  //consequence table visibility handler
  onConsequenceVisibilityHandler = () => {
    //let status = this.state.consequenceShowStatus;

    this.setState((prevState) => ({
      consequenceShowStatus: !prevState.consequenceShowStatus,
    }));
  };

  //handle remove event from event component
  onEventRemoveHandler = (event, index) => {
    //get the events from state and delete event whose index is passed from event form.
    //also get the id so as to push inside metadata object.
    let currentEvents = [...this.state.incident.events];
    const id = currentEvents[index].eventId;
    //currentEvents.splice(index, 1);
    currentEvents[index].actionTaken = "delete";

    //get the deleteEvent array from state and push the deleted event id to arrays so that api understands what was deleted
    let deletedEvent = this.state.metadata.deletedEvent;
    currentEvents=currentEvents.filter((event)=>event.actionTaken !== "delete");
    if (this.state.incident.incidentId !== null) {
      //id null would mean the event is a new event added while in edit mode,
      //and so will not have any id assigned
      if (id !== null) {
        deletedEvent.push(id);
        currentEvents=currentEvents.filter((event)=>event.actionTaken !== "delete");
      }
    }

    //set state operation
    this.setState((prevState) => ({
      incident: {
        ...prevState.incident,
        events: currentEvents,
        metadata: {
          ...prevState.metadata,
          deletedEvent: deletedEvent,
        },
      },
    }));
  };

  onDropDownSelection = (selectedValue, identifier, index) => {
    //if cancel is pressed without choosing anything, keep old value intact
    if (selectedValue.length === 0) {
      return;
    }
    const eventState = [...this.state.incident.events];
    //1: barrier
    //2: work process
    if (identifier === 1) {
      eventState[index] = {
        ...eventState[index],
        isEdited: true,
        actionTaken: eventState[index].eventId === null ? "add" : "edit",
        timpBarrier1: selectedValue[0] !== undefined ? selectedValue[0] : "",
        timpBarrier2: selectedValue[1] !== undefined ? selectedValue[1] : "",
        timpBarrier3: selectedValue[2] !== undefined ? selectedValue[2] : "",
        timpBarrier4: selectedValue[3] !== undefined ? selectedValue[3] : "",
      };
    } else if (identifier === 2) {
      eventState[index] = {
        ...eventState[index],
        isEdited: true,
        actionTaken: eventState[index].eventId === null ? "add" : "edit",
        workProcess1: selectedValue[0] !== undefined ? selectedValue[0] : "",
        workProcess2: selectedValue[1] !== undefined ? selectedValue[1] : "",
        workProcess3: selectedValue[2] !== undefined ? selectedValue[2] : "",
        workProcess4: selectedValue[3] !== undefined ? selectedValue[3] : "",
        workProcess5: selectedValue[4] !== undefined ? selectedValue[4] : "",
        workProcess6: selectedValue[5] !== undefined ? selectedValue[5] : "",
      };
    } else {
      console.warn(`Create, onDropDownSelection; Identifier not recognised`);
    }

    this.setState({
      incident: {
        ...this.state.incident,
        events: eventState,
      },
    });
  };

  //function to fetch incident details from api and load the value to state
  onLoadUserData = async (id, accessToken) => {
    const siop = await apiCall(
      this.props.getAccessToken,
      this.props.userAccount,
      accessToken,
      apiURLList.siop,
      "get",
      {
        params: { ID: id },
      }
    );

    if (siop.status === 200) {
      this.loadDataToView(siop.data);
    } else {
      this.setState({
        snackBarOpen: true,
        snackBarMessage: siop.message,
        snackBarStatus: "error",
      });
    }
  };

  //alter incoming date format to what UI requires
  dateFormat = (inp) => {
    let day = inp.substr(0, 2);
    let month = inp.substr(3, 2);
    let year = inp.substr(6);

    let formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  };

  //mapping view and edit data fetched from db
  loadDataToView = (data) => {
    let eventState = [];

    data.events.forEach((singleEvent, index) => {
      eventState[index] = {
        eventId: singleEvent.id,
        workProcessBarrier:
          singleEvent.eventType !== null ? singleEvent.eventType.id : "",
        eventDescription: singleEvent.deviationDescription,
        actionLesson: singleEvent.lessons,
        discipline:
          singleEvent.discipline !== null ? singleEvent.discipline.id : "",
        designError: singleEvent.designError,
        workProcess1: singleEvent.workProcess1,
        workProcess2: singleEvent.workProcess2,
        workProcess3: singleEvent.workProcess3,
        workProcess4: singleEvent.workProcess4,
        workProcess5: singleEvent.workProcess5,
        workProcess6: singleEvent.workProcess6,
        timpBarrier1: singleEvent.timpBarrier1,
        timpBarrier2: singleEvent.timpBarrier2,
        timpBarrier3: singleEvent.timpBarrier3,
        timpBarrier4: singleEvent.timpBarrier4,
        workProcessBarrierText: singleEvent.workProcessBarrierText,
        eventOnePagerSwitch: singleEvent.onePagerFlag,
        isEdited: false,
        actionTaken: null,
        eventSeqNo: singleEvent.eventSeqNo,
        error: cloneDeepWith(this.state.eventFormField.error),
      };
    });

    let consequenceState = [];
    data.consequenceMatrices.forEach((consequence, index) => {
      consequenceState[index] = {
        consequenceId: consequence.id === null ? "" : consequence.id,
        actual: consequence.actual === null ? "" : consequence.actual,
        potential: consequence.potential === null ? "" : consequence.potential,
        //if no consequnce selected while saving, place a "" value
        category:
          consequence.consequenceCategory !== null
            ? consequence.consequenceCategory.description === "Others"
              ? consequence.category
              : ""
            : "",
        categoryId: consequence.categoryId,
        categoryDescription:
          consequence.consequenceCategory !== null
            ? consequence.consequenceCategory.description === "Others"
              ? consequence.category
              : consequence.consequenceCategory.description
            : "",
        categoryOnePagerSwitch: consequence.onePagerFlag,
        description1: consequence.description1,
        description2: consequence.description2,
        description3: consequence.description3,
        isEdited: false,
        action: null,
        error: JSON.parse(
          JSON.stringify(this.state.consequenceFormField.error)
        ),
      };
    });

    let incident = {
      ...this.state.incident,
      incidentId: data.incidents.id,
      synergi:
        data.incidents.synergiId === null ? "" : data.incidents.synergiId,
      date:
        data.incidents.incidentDate === null
          ? ""
          : this.dateFormat(data.incidents.incidentDate),
      country: data.incidents.country,
      unit: data.incidents.unitName,
      businessArea: data.incidents.businessArea,
      area: data.incidents.area !== null ? data.incidents.area.id : "",
      title: data.incidents.incidentTitle,
      description: data.incidents.incidentDescription,
      approverComment: data.comments,
      keyLesson: data.incidents.keyLesson,
      lifeSavingRule:
        data.incidents.lifeSavingRule !== null
          ? data.incidents.lifeSavingRule.id
          : "",
      overallPotential: data.incidents.overallPotential,
      majorAccidentPotential: data.incidents.majorAccRisk,
      image1: {
        data: null,
        name: null,
        isEdited: false,
        action: null,
      },
      image2: {
        data: null,
        name: null,
        isEdited: false,
        action: null,
      },
      image3: {
        data: null,
        name: null,
        isEdited: false,
        action: null,
      },
      video: {
        data: null,
        name: null,
        isEdited: false,
        action: null,
      },
      error: {
        country: { indicator: false, text: "" },
        unit: { indicator: false, text: "" },
        businessArea: { indicator: false, text: "" },
        title: { indicator: false, text: "" },
        description: { indicator: false, text: "" },
        keyLesson: { indicator: false, text: "" },
      },
      events: eventState,
      consequenceList: consequenceState,
    };

    let isApproved = false;

    //to hide submit button in all siop page, where only approved SIOP exists
    if (
      data.incidents.approvalFlag === "Y" &&
      data.incidents.finalSubmitFlag === "Y"
    )
      isApproved = true;
    //to hide submit button in approver page, where submission is not permitted
    if (
      data.incidents.approvalFlag === "N" &&
      data.incidents.finalSubmitFlag === "Y"
    )
      isApproved = true;

    this.setState({
      incident: incident,
      displayImage1: data.incidents.image1Url,
      displayImage2: data.incidents.image2Url,
      displayImage3: data.incidents.image3Url,
      image1InitialState: data.incidents.image1Url === null ? false : true, //set status as true if the image is sent from db, false if no image present
      image2InitialState: data.incidents.image2Url === null ? false : true,
      image3InitialState: data.incidents.image3Url === null ? false : true,
      videoInitialState: data.incidents.videoImage4Url === null ? false : true,
      displayVideo: data.incidents.videoImage4Url,
      loading: false,
      isApproved: isApproved,
    });
  };

  instructions = () => {
    if (this.props.location.pathname === routingPath.CREATE) {
      return <CreateInstruction />;
    } else {
      if (this.props.location.state.viewStatus) {
        return <ViewInstruction />;
      } else {
        return <EditInstruction />;
      }
    }
  };

  onBottomNavigationChange = async (event, newValue) => {
    //save the siop before performing any further action
    await this.handleSave();
    if (newValue === "Cancel") {
      //await this.handleSave();
      this.setState({
        bottomBarValue: newValue,
      });
      this.props.history.goBack();
    }
    if (newValue === "Submit") {
      this.setState({
        bottomBarValue: newValue,
        dialogText:
          "Please verify that personal information has not been entered in any of the forms.  Are you sure you want to submit?",
        dialogType: "Submit",
        dialogOpen: true,
      });
      //await this.handleSave();
    }
    if (newValue === "ViewOnePager") {
      //await this.handleSave();
      this.generateOnePager();
    }
  };

  mapToSaveApiFormat = (siopData) => {
    //event data mapping
    let eventData = [];
    let causeData = [
      {
        causeProcess1: "string",
        causeProcess2: "string",
        causeProcess3: "string",
        causeProcess4: "string",
      },
    ];
    siopData.events.forEach((element) => {
     
      //if (element.isEdited === true && element.eventId !== -1) {
      eventData.push({
        //sending -1 s event id if its a new event addition, so
        //that api can handle.
      
        id: element.eventId === null  || typeof element.eventId === 'undefined' ? -1 : parseInt(element.eventId, 10),
        eventType:
          element.workProcessBarrier === ""
            ? 0
            : parseInt(element.workProcessBarrier, 10),
        discipline:
          element.discipline === "" ? 0 : parseInt(element.discipline, 10),
        deviationDescription: element.eventDescription,
        lessons: element.actionLesson,
        workProcess1: element.workProcess1,
        workProcess2: element.workProcess2,
        workProcess3: element.workProcess3,
        workProcess4: element.workProcess4,
        workProcess5: element.workProcess5,
        workProcess6: element.workProcess6,
        timpBarrier1: element.timpBarrier1,
        timpBarrier2: element.timpBarrier2,
        timpBarrier3: element.timpBarrier3,
        timpBarrier4: element.timpBarrier4,
        workProcessBarrierText: element.workProcessBarrierText,
        onePagerFlag: element.eventOnePagerSwitch,
        isEdited: element.isEdited,
        action: ((element.eventId === -1 || typeof element.eventId === 'undefined' || element.eventId === null) && element.isEdited === true ) ? "add" :  (element.eventId > 0 && element.isEdited === true )? "edit": null ,
        eventSeqNo: element.eventSeqNo,
        causeAnalysis: causeData,
        
      });
      //}
    });
 
    //media data mapping
    const imageData = [];
    imageData.push({
      fileBytesArray:
        siopData.image1.data === null ? null : encode(siopData.image1.data),
      fileName: siopData.image1.name,
      IsEdited: siopData.image1.isEdited,
      Action: siopData.image1.action,
    });
    imageData.push({
      fileBytesArray:
        siopData.image2.data === null ? null : encode(siopData.image2.data),
      fileName: siopData.image2.name,
      IsEdited: siopData.image2.isEdited,
      Action: siopData.image2.action,
    });
    imageData.push({
      fileBytesArray:
        siopData.image3.data === null ? null : encode(siopData.image3.data),
      fileName: siopData.image3.name,
      IsEdited: siopData.image3.isEdited,
      Action: siopData.image3.action,
    });
    const videoData = {
      fileBytesArray:
        siopData.video.data === null ? null : encode(siopData.video.data),
      fileName: siopData.video.name+".mp4",
      IsEdited: siopData.video.isEdited,
      Action: siopData.video.action,
    };

    //consequence data mapping
    const consequenceData = [];
    siopData.consequenceList.forEach((element) => {
      
      consequenceData.push({
        id:
          element.consequenceId === null || typeof element.consequenceId === 'undefined'
            ? -1
            : parseInt(element.consequenceId, 10),
        actual: element.actual === "" ? null : parseInt(element.actual, 10),
        potential:
          element.potential === "" ? null : parseInt(element.potential, 10),
        category: element.category,
        categoryId: element.categoryId === "" ? null : element.categoryId,
        onePagerFlag: element.categoryOnePagerSwitch,
        description1: element.description1,
        description2: element.description2,
        description3: element.description3,
        isEdited: element.isEdited,
        action: ((element.consequenceId === -1 || typeof element.consequenceId === 'undefined' || element.consequenceId == null ) && element.isEdited === true ) ? "add" :  (element.consequenceId > 0 && element.isEdited === true )? "edit": null , // === null ? "no" : element.action, //this is dirty fix. correct this soon to just "element.action", for consistency across code reason
      });
    });
 
  

    //creating an object for event and consequence with deleted ids,
    //letting api know which all event and consequence were deleted during edit phase
    if (this.state.incident.incidentId !== null) {
      this.state.metadata.deletedEvent.forEach((deletedEventId) => {
        if(deletedEventId != -1)
        {
        var deletedEventObject = {
          id: deletedEventId,
          isEdited: true,
          action: "delete",
        };
      }
      if(deletedEventId == -1 ){
        deletedEventObject = {
         id: deletedEventId,
         isEdited: false,
         action: "null",
       };
     }

        eventData.push(deletedEventObject);
      });

      this.state.metadata.deletedConsequence.forEach((deletedConsequenceId) => {
        if(deletedConsequenceId != -1 ){
        var deletedConsequenceObject = {
          id: deletedConsequenceId,
          isEdited: true,
          action: "delete",
        };
      }
      if(deletedConsequenceId == -1 ){
         deletedConsequenceObject = {
          id: deletedConsequenceId,
          isEdited: false,
          action: "null",
        };
      }

     
        consequenceData.push(deletedConsequenceObject);
      });
    }

    //master data mapping
    const postData = {
      incidents: {
        //passing -1 to api so as to handle null/empty incident id
        id:
          siopData.incidentId === null ? -1 : parseInt(siopData.incidentId, 10),
        userId: siopData.userId,
        synergiId:
          siopData.synergi === "" ? null : parseInt(siopData.synergi, 10),
        incidentDate: siopData.date,
        incidentTitle: siopData.title,
        incidentDescription: siopData.description,
        country: siopData.country,
        unitName: siopData.unit,
        businessArea: siopData.businessArea,
        keyLesson: siopData.keyLesson,
        onePager: null,
        majorAccRisk: siopData.majorAccidentPotential,
        //overallPotential: siopData.overallPotential,
        area: siopData.area === "" ? 0 : parseInt(siopData.area, 10),
        lifeSavingRule:
          siopData.lifeSavingRule === ""
            ? 0
            : parseInt(siopData.lifeSavingRule, 10),
        isEdited: siopData.isEdited,
        action: siopData.actionTaken,
        finalSubmitFlag: null,
        approvalFlag: null,
        approverComment: null,
        imagesObj: imageData,
        videoObj: videoData,
        //consequenceMatrices: consequenceData,
        //events: eventData,
      },
      consequenceMatrices: consequenceData,
      events: eventData,
    };

    return postData;
  };

  commentNotification = () => {
    if (this.props.location.pathname !== routingPath.CREATE) {
      if (this.props.location.state.approverCommentStatus) {
        return (
          <ApproverComment
            approverComment={this.state.incident.approverComment}
            loggedInUser={this.props.user}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  render() {
    //looping in to create array of all the events in state
    const event = this.state.incident.events.map((eventObj, index) => {
      let workProcessArray = [];
      let barrierArray = [];

      //assign sequence no to new event added
      if (eventObj.eventSeqNo === null) {
        eventObj.eventSeqNo = index + 1;
      }

      if (eventObj.workProcess1) workProcessArray.push(eventObj.workProcess1);
      if (eventObj.workProcess2) workProcessArray.push(eventObj.workProcess2);
      if (eventObj.workProcess3) workProcessArray.push(eventObj.workProcess3);
      if (eventObj.workProcess4) workProcessArray.push(eventObj.workProcess4);
      if (eventObj.workProcess5) workProcessArray.push(eventObj.workProcess5);
      if (eventObj.workProcess6) workProcessArray.push(eventObj.workProcess6);

      if (eventObj.timpBarrier1) barrierArray.push(eventObj.timpBarrier1);
      if (eventObj.timpBarrier2) barrierArray.push(eventObj.timpBarrier2);
      if (eventObj.timpBarrier3) barrierArray.push(eventObj.timpBarrier3);
      if (eventObj.timpBarrier4) barrierArray.push(eventObj.timpBarrier4);

      if (eventObj.actionTaken === "delete") {
        return null;
      } else {
        return (
          <Event
            key={index}
            totalEventCount={this.state.incident.events.length}
            eventDetails={eventObj}
            onFieldChange={(event) => this.onEventChangeHandler(event, index)}
            onEventDelete={(event) => this.onEventRemoveHandler(event, index)}
            currentWorkProcessValue={workProcessArray}
            currentTimpBarrierValue={barrierArray}
            referenceData={this.props.referenceData}
            isViewActive={this.state.isViewActive}
            accessToken={this.props.accessToken}
            reorderEvent={(direction, sequenceNo) =>
              this.onReorderEvent(direction, sequenceNo)
            }
            onDropDownSelection={(selectedValue, identifier) =>
              this.onDropDownSelection(selectedValue, identifier, index)
            }
          />
        );
      }
    });

    //looping in to create consequences from consequence list
    const consequenceArray = this.state.incident.consequenceList.map(
      (consequenceObj, index) => {
        if (consequenceObj.action === "delete") {
          return null;
        } else {
          return (
            <ConsequenceEdit
              key={index}
              //consequences={this.state.incident.consequenceList}
              consequences={consequenceObj}
              consequenceChangeHandler={(event) =>
                this.onConsequenceChangeHandler(event, index)
              }
              onConsequenceDelete={(event) =>
                this.onConsequenceDeleteHandler(event, index)
              }
              referenceData={this.props.referenceData}
            />
          );
        }
      }
    );

    let addEventButton = (
      <Button style={{ margin: "10px" }} onClick={this.onAddEventHandler}>
        Add Event
      </Button>
    );

    let eventHeader = (
      <React.Fragment>
        <Typography style={{ marginLeft: "1%" }} variant={"h5"}>
          Create events
        </Typography>
        <Divider />
      </React.Fragment>
    );

    if (this.state.isViewActive) {
      addEventButton = null;

      if (event.length === 0) eventHeader = null;
    }

    //load the page if data is loaded(for view and edit), else show spinner
    var alertComponent = (
      <CustomAlerts
        dialogOpen={this.state.dialogOpen}
        handleDialogClose={this.handleDialogClose}
        dialogText={this.state.dialogText}
        dialogConfirmation={this.handleDialogConfirmation}
        snackBarOpen={this.state.snackBarOpen}
        snackBarStatus={this.state.snackBarStatus}
        snackBarText={this.state.snackBarMessage}
        handleSnackBarClose={this.handleSnackBarClose}
        dialogMessageText={this.state.dialogMessageText}
        dialogMessageLink={this.state.dialogMessageLink}
        dialogMessageOpen={this.state.dialogMessageOpen}
      />
    );

    var overallJSX = (
      <React.Fragment>
        {this.instructions()}
        <Form>
          <div
            className="row p-md-3 incidentBorder"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <div className="col-md-9">
              <Incident
                fieldChange={(event) => this.onIncidentChangeHandler(event)}
                dateChange={(date) => this.onDateChangeHandler(date)}
                checkboxChange={(event) => this.onCheckboxSwitchHandler(event)}
                loadFromSynergi={(event) => this.onLoadFromSynergi(event)}
                mediaChange={(event) => this.onMediaChangeHandler(event)}
                onDelete={(event, imageIdentifier, displayImageIdentifier) =>
                  this.onMediaDeleteHandler(
                    event,
                    imageIdentifier,
                    displayImageIdentifier
                  )
                }
                incidentDetails={this.state.incident}
                displayImages={this.state.displayImageUrl}
                referenceData={this.props.referenceData}
                isViewActive={this.state.isViewActive}
                displayImage1={this.state.displayImage1}
                displayImage2={this.state.displayImage2}
                displayImage3={this.state.displayImage3}
                displayVideo={this.state.displayVideo}
              />
            </div>
            <div className="col-md-3">
              <div className="row-12" style={{ textAlign: "right" }}>
                {this.commentNotification()}
              </div>
              <div className="row-12">
                <Button onClick={this.onConsequenceVisibilityHandler}>
                  Consequence(s)(click to expand/Roll up)
                </Button>
                {
                  //if edit phase, show "consequenceEdit component, else show consequence component if in view mode"
                  this.state.consequenceShowStatus ? (
                    this.state.isViewActive === true ? (
                      <Consequence
                        consequences={this.state.incident.consequenceList}
                        pathname={this.props.location.pathname}
                      />
                    ) : (
                      <React.Fragment>
                        {consequenceArray}
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button onClick={this.onConsequenceAddHandler}>
                              <AddCircleOutlineOutlinedIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  ) : null
                }
              </div>
            </div>
          </div>

          <Divider />
          {eventHeader}

          <div className="row">{event}</div>

          <div style={{ margin: "10px" }}>{addEventButton}</div>
        </Form>
        <BottomBar
          bottomBarValue={this.state.bottomBarValue}
          onBottomNavigationChange={this.onBottomNavigationChange}
          isViewActive={this.state.isViewActive}
          onePagerProgress={this.state.onePagerProgress}
          isApproved={this.state.isApproved}
        />
        {alertComponent}
      </React.Fragment>
    );

    if (this.state.loading) {
      overallJSX = (
        <React.Fragment>
          <StarProgress
            color="primary"
            style={{ marginLeft: "50%", marginTop: "15%" }}
          />
          {alertComponent}
        </React.Fragment>
      );
    }

    return overallJSX;
  }
}

const mapStateToProps = (state) => {
  return {
    referenceData: state.referenceData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reference: (referenceData) => {
      dispatch({ type: "REFERENCE_UPD", referenceData: referenceData });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
