import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "@equinor/eds-core-react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Constant, { routingPath } from "../../../Constant";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonMaterial from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//userId and synergiId is being passed as parameter with Edit and view button.
//This would let the component(which will be rendered) to load the data related to user or synergiId

const EventListTable = (props) => {
  const tableBody = props.tableContent.map((element) => {
    const button = () => {
      if (props.path === routingPath.MY_SIOP) {
        return (
          <React.Fragment>
            <Tooltip title="View" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.incidentId, "view");
                }}
              >
                <VisibilityOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Edit" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.incidentId, "edit");
                }}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Delete" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.deleteClicked(element.incidentId);
                }}
              >
                <DeleteForeverOutlinedIcon />
              </Button>
            </Tooltip>
          </React.Fragment>
        );
      } else if (props.path === routingPath.APPROVAL_LIST) {
        return (
          <React.Fragment>
            <Tooltip title="Edit" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.incidentId, "edit");
                }}
              >
                <EditOutlinedIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Approve/Reject" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionHandler(element.incidentId);
                }}
              >
                <CheckCircleOutlineOutlinedIcon />
              </Button>
            </Tooltip>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };

    //limit number of character s in description
    const abbreviatedDescription = (description) => {
      if (description.length <= Constant.TEXT_LENGTH) {
        return description;
      } else {
        return description.substring(0, Constant.TEXT_LENGTH) + "...";
      }
    };

    const eventValue = (element) => {
      let event = element;
      let process;

      //get last value of workprocess or barrier. Get text entered by user if present
      if (
        event.workProcessBarrierText !== "" &&
        event.workProcessBarrierText !== null
      ) {
        process = event.workProcessBarrierText;
      } else {
        if (event.eventTypeId === 1) {
          let timpArray = [
            event.timpBarrier1,
            event.timpBarrier2,
            event.timpBarrier3,
            event.timpBarrier4,
          ];
          if (timpArray.indexOf(null) !== -1) {
            process = timpArray[timpArray.indexOf(null) - 1];
          } else if (timpArray.indexOf("") !== -1) {
            process = timpArray[timpArray.indexOf("") - 1];
          } else {
            process = timpArray[timpArray.length - 1];
          }
        } else if (event.eventTypeId === 2) {
          let wProcessArray = [
            event.workProcess1,
            event.workProcess2,
            event.workProcess3,
            event.workProcess4,
            event.workProcess5,
            event.workProcess6,
          ];
          if (wProcessArray.indexOf(null) !== -1) {
            process = wProcessArray[wProcessArray.indexOf(null) - 1];
          } else if (wProcessArray.indexOf("") !== -1) {
            process = wProcessArray[wProcessArray.indexOf("") - 1];
          } else {
            process = wProcessArray[wProcessArray.length - 1];
          }
        }
      }

      return process;
    };

    return (
      <TableRow key={element.id} hover={true}>
        <TableCell style={{ width: 50 }}>{eventValue(element)}</TableCell>
        <TableCell style={{ width: 550 }}>
          {abbreviatedDescription(element.deviationDescription)}
        </TableCell>
        <TableCell style={{ width: 400 }}>{element.lessons}</TableCell>
        <TableCell
          style={
            props.path === routingPath.ALL_SIOP
              ? { width: 300 }
              : { width: 200 }
          }
        >
          {/* view, edit and delete buttons */}
          {button()}

          {/* one pager button; use can choose one pager in 1 of 3 language if on all siop page*/}
          {props.path === routingPath.ALL_SIOP ? (
            <ButtonGroup variant="text" color="primary">
              <ButtonMaterial
                onClick={() => {
                  props.actionClicked(
                    element.incidentId,
                    "onePager",
                    Constant.LANGUAGE_ENGLISH
                  );
                }}
                style={{padding:"12px"}}
                size="small"
              >
                One Pager
              </ButtonMaterial>
              <ButtonMaterial
                onClick={() => {
                  props.actionClicked(
                    element.incidentId,
                    "onePager",
                    Constant.LANGUAGE_NORWEGIAN
                  );
                }}
                style={{paddingRight:"12px"}}
                size="small"
              >
                Ensider
              </ButtonMaterial>
              <ButtonMaterial
                onClick={() => {
                  props.actionClicked(
                    element.incidentId,
                    "onePager",
                    Constant.LANGUAGE_PORTUGUESE
                  );
                }}
                size="small"
              >
                Um Pager
              </ButtonMaterial>
            </ButtonGroup>
          ) : (
            <Tooltip title="One Pager" arrow>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  props.actionClicked(element.incidentId, "onePager");
                }}
              >
                <AssignmentIcon />
              </Button>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Box m={5}>
      <Grid item>
        <Typography
          style={{
            fontStyle: "italic",
            fontSize: "0.9rem",
          }}
          gutterBottom
        >
          *The below list is sorted based on newest first
        </Typography>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {props.eventType === "Timp" ? (
                <strong>Barrier</strong>
              ) : (
                <strong>Work Process</strong>
              )}
            </TableCell>
            <TableCell>
              <strong>Description</strong>
            </TableCell>
            <TableCell>
              <strong>Lessons</strong>
            </TableCell>
            <TableCell style={{paddingLeft:"40px"}}>

              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </Box>
  );
};

export default EventListTable;
