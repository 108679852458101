import Constant from "../Constant.js";
import { cloneDeepWith } from "lodash";

const searchParameter = {
  country: [],
  unit: [],
  businessArea: [],
  area: [],
  lifeSavingRule: [],
  discipline: [],
  category: [],
  startDate: "",
  endDate: "",
  customSearch: "",
  countryText: "",
  unitText: "",
  businessAreaText: "",
  categoryText: "",
  yearRange: [Constant.START_YEAR, Constant.END_YEAR],
  workProcess: [],
  timpBarrier: []
};

const searchReducers = (
  state = {
    pageNumber: 1,
    defaultSearchPageAsIncident: true,
    searchParameter: cloneDeepWith(searchParameter),
    referenceData: {},
  },
  actions
) => {
  if (actions.type === "SEARCH_RESET") {
    return {
      pageNumber: 1,
      defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
      searchParameter: cloneDeepWith(searchParameter),
      referenceData: state.referenceData,
    };
  }
  if (actions.type === "SEARCH_AREA") {
    var searchFields = cloneDeepWith(searchParameter);
    searchFields.area[0] = actions.value;

    return {
      pageNumber: 1,
      defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
      searchParameter: searchFields,
      referenceData: state.referenceData,
    };
  }
  if (actions.type === "SEARCH_UPDATED") {
    return {
      pageNumber: state.pageNumber,
      defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
      searchParameter: actions.searchParameter,
      referenceData: state.referenceData,
    };
  }
  if (actions.type === "PAGE_CHANGED") {
    return {
      pageNumber: actions.pageNumber,
      defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
      searchParameter: state.searchParameter,
      referenceData: state.referenceData,
    };
  }

  if (actions.type === "REFERENCE_UPD") {
    return {
      pageNumber: state.pageNumber,
      defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
      searchParameter: state.searchParameter,
      referenceData: actions.referenceData,
    };
  }

  if (actions.type === "SEARCH_FETCH") {
    return {
      pageNumber: 1,
      defaultSearchPageAsIncident: state.defaultSearchPageAsIncident,
      searchParameter: state.searchParameter,
      referenceData: state.referenceData,
    };
  }

  // performed when search button is switched between incident or event
  // storing state will help to come back to same page from where one pager view was chosen to be seen
  if(actions.type === "SEARCH_PAGE_CHANGE"){
    return {
      pageNumber: 1,
      defaultSearchPageAsIncident: actions.defaultSearchPageAsIncident,
      searchParameter: state.searchParameter,
      referenceData: state.referenceData,
    };
  }
  return state;
};

export default searchReducers;
