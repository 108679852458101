import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
const VideoPlayer = ({ videoUrl }) => {

  const [showVideo, setShowVideo] = useState(false);

  const handlePlay = () => {
    setShowVideo(true);
    document.getElementById('video-container-background').style.filter = 'blur(15px)';
  };

  const handleClose = () => {
    setShowVideo(false);
    document.getElementById('video-container-background').style.filter = 'none';
  };

  return (<>
    <Button variant="contained" color="primary"  style={{minWidth:"200px",minHeight:"40px",marginLeft:"90px"}} onClick={handlePlay}>
      Watch the incident Video
    </Button>
    {showVideo && (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '9998',
      }}>
        <div id="video-container-background" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '9999',
          padding: '40px',
          paddingBottom:"60px",
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          filter: 'none',
        }}>
          <Button onClick={handleClose} style={{
            position: 'absolute',
            top: '0',
            right: '0',
            marginBottom:"5px",
            marginRight:"400px",
            marginTop:"488px",
            border: 'none',
            cursor: 'pointer',
          }}>Close</Button>
          <video src={videoUrl} controls style={{
            width: '100%',
            height: 'auto',
            filter: 'none',
          }}></video>
        </div>
      </div>
    )}
  </>
  );
};
export default VideoPlayer;