import React from "react";
import "./Event.css";
import makeStyles from "@material-ui/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
//import Button from "@material-ui/core/Button";
import { Button } from "@equinor/eds-core-react";
import DropDown from "./DropDown/DropDown";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import { eventCategory } from "../../../Constant";

//enum for event type
const eventType = {
  TIMP_BARRIER: eventCategory.TIMP_BARRIER,
  WORK_PROCESS: eventCategory.WORK_PROCESS,
};

const useStyles = makeStyles({
  root: {
    "& .MuiTextField-root": {
      width: "25ch",
    },
  },
  removeButton: {
    margin: "10px",
    color: "white",
    backgroundColor: "#e52d2d",
    "&:hover": {
      backgroundColor: "#b22323",
    },
  },
});

function processBarrierRendering(props) {
  if (props.eventDetails.workProcessBarrier === eventType.WORK_PROCESS) {
    return (
      <DropDown
        error={props.eventDetails.error.workProcessBarrierText.indicator}
        helperText={props.eventDetails.error.workProcessBarrierText.text}
        selectText="Select work process"
        changeText="Change work process"
        isViewActive={props.isViewActive}
        accessToken={props.accessToken}
        levelOneList={props.referenceData.workProcess1Lst}
        currentDropDownValue={props.currentWorkProcessValue}
        onDropDownSelection={props.onDropDownSelection}
        identifier={eventType.WORK_PROCESS}
        extraTextValue={props.eventDetails.workProcessBarrierText}
        onChange={props.onFieldChange}
        onEventTypeChanged={props.eventDetails.eventTypeChanged}
      />
    );
  } else if (props.eventDetails.workProcessBarrier === eventType.TIMP_BARRIER) {
    return (
      <DropDown
        error={props.eventDetails.error.workProcessBarrierText.indicator}
        helperText={props.eventDetails.error.workProcessBarrierText.text}
        selectText="Select Barrier"
        changeText="Change Barrier"
        isViewActive={props.isViewActive}
        accessToken={props.accessToken}
        levelOneList={props.referenceData.timpBarrier1Lst}
        currentDropDownValue={props.currentTimpBarrierValue}
        onDropDownSelection={props.onDropDownSelection}
        identifier={eventType.TIMP_BARRIER}
        extraTextValue={props.eventDetails.workProcessBarrierText}
        onChange={props.onFieldChange}
        onEventTypeChanged={props.eventDetails.eventTypeChanged}
      />
    );
  } else {
    return null;
  }
}

const Event = (props) => {
  const classes = useStyles();

  let eventRemoveButton = null;

  if (!props.isViewActive)
    eventRemoveButton = (
      <Button
        className={classes.removeButton}
        onClick={props.onEventDelete}
        variant="contained"
      >
        Remove
      </Button>
    );

  //creating discipline and event type select list from reference data
  let disciplineList;
  let eventTypeList;

  //filling event type list from reference data
  if (props.referenceData.eventTypeLst !== undefined) {
    eventTypeList = props.referenceData.eventTypeLst.map((eventTypeObj) => {
      return (
        <MenuItem key={eventTypeObj.id} value={eventTypeObj.id}>
          {eventTypeObj.name}
        </MenuItem>
      );
    });
  } else {
    console.warn("Event.js; Event list is empty. Check data received from api");
    eventTypeList = null;
  }

  if (props.referenceData.disciplineLst !== undefined) {
    disciplineList = props.referenceData.disciplineLst.map((disciplineObj) => {
      return (
        <MenuItem key={disciplineObj.id} value={disciplineObj.id}>
          {disciplineObj.name}
        </MenuItem>
      );
    });
  } else {
    console.warn(
      "Event.js; discipline list is empty. Check data received from api"
    );
    disciplineList = null;
  }

  return (
    <div
      id="EventTab"
      className="col-md-5 p-md-3 ml-md-5 mr-md-5 mt-md-2 eventBorder"
    >
      {/* arrow for event sequence change */}
      <div className="row">
        <div className="col-10"></div>
        <div className="col-1">
          {/* dont show left arrow if first event or there is only one event */}
          <Button
            disabled={
              props.eventDetails.eventSeqNo === 1 || props.totalEventCount === 1
                ? true
                : false || props.isViewActive
            }
            variant="ghost_icon"
            onClick={() =>
              props.reorderEvent("left", props.eventDetails.eventSeqNo)
            }
          >
            <ChevronLeftOutlinedIcon />
          </Button>
        </div>
        <div className="col-1">
          {/* dont show right arrow if last event or there is only one event */}
          <Button
            disabled={
              props.eventDetails.eventSeqNo === props.totalEventCount ||
              props.totalEventCount === 1
                ? true
                : false || props.isViewActive
            }
            variant="ghost_icon"
            onClick={() =>
              props.reorderEvent("right", props.eventDetails.eventSeqNo)
            }
          >
            <ChevronRightOutlinedIcon />
          </Button>
        </div>
      </div>

      <div id="workProcessBarrier" className="form-group">
        <TextField
          disabled={props.isViewActive}
          name="workProcessBarrier"
          label="Choose Work process/Barrier"
          select
          value={props.eventDetails.workProcessBarrier}
          onChange={props.onFieldChange}
        >
          <MenuItem disabled></MenuItem>
          {eventTypeList}
        </TextField>
      </div>

      <div id="processParent" className="form-group">
        {processBarrierRendering(props)}
      </div>

      <div id="eventDescription" className="form-group">
        <TextField
          error={props.eventDetails.error.eventDescription.indicator}
          helperText={props.eventDetails.error.eventDescription.text}
          disabled={props.isViewActive}
          name="eventDescription"
          placeholder="Please fill in description"
          multiline
          label="Description"
          value={props.eventDetails.eventDescription}
          onChange={props.onFieldChange}
          minRows={3}
        ></TextField>
      </div>

      <div id="actionLesson" className="form-group">
        <TextField
          error={props.eventDetails.error.actionLesson.indicator}
          helperText={props.eventDetails.error.actionLesson.text}
          disabled={props.isViewActive}
          name="actionLesson"
          multiline
          placeholder="Lesson/Action"
          label="Lesson/Action"
          value={props.eventDetails.actionLesson}
          onChange={props.onFieldChange}
          minRows={3}
        ></TextField>
      </div>

      <div className="form-row">
        <div className="form-group col">
          <TextField
            disabled={props.isViewActive}
            name="discipline"
            label="Discipline"
            select
            value={props.eventDetails.discipline}
            onChange={props.onFieldChange}
          >
            <MenuItem disabled></MenuItem>
            {disciplineList}
          </TextField>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col">{eventRemoveButton}</div>
        <div className="form-group col">
          {!props.isViewActive ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="eventOnePagerSwitch"
                    checked={props.eventDetails.eventOnePagerSwitch}
                    onChange={props.onFieldChange}
                  />
                }
                label="Show on One Pager"
              />
            </FormGroup>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Event;
